import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { getBookings} from "../../../apis/bookingApi";
import {Link} from "react-router-dom";
import { useAuth } from "../../../auth/AuthContext";

const UpcomingBookings = ({ handleDeleteBookingClick}) => {
    const [bookings, setBookings] = useState([]);
    const { hasPermission } = useAuth();

    useEffect(() => {
        const loadInitialData = async () => {
            await loadBookings();
        };

        const loadBookings = async () => {
            try {
                const res = await getBookings();
                const now = new Date();
                const futureBookings = res.filter(booking => new Date(booking.time_los) > now);
                setBookings(futureBookings || []);
            } catch (error) {
                console.error('Error loading bookings:', error);
            }
        };
        loadInitialData();

        const interval = setInterval(() => {
            loadBookings();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const timeToNow = (time) => {
        const now = new Date();
        const futureTime = new Date(time);
        const diffMs = futureTime - now;

        if (diffMs > 0) {
            if (diffMs < 30 * 60 * 1000) { 
                return '-' + moment.utc(diffMs).format('mm:ss'); 
            } else {
                return moment(futureTime).fromNow(); 
            }
        } else {
            return moment(futureTime).fromNow();
        }
    };

    const duration = (startTime, endTime) => moment.utc(new Date(endTime) - new Date(startTime)).format('mm:ss');

    const renderStatusIcon = (booking) => {
        const status_map = {
            OK: 'normal',
            WARNING: 'caution',
            STANDBY: 'standby',
            ERR: 'critical',
            null: ''
        };
    
        return status_map[booking.status?.level || null];
    };

    return (
        <rux-table>
            <rux-table-body>
                <rux-table-header-row selected="false">
                    <rux-table-header-cell>Detail</rux-table-header-cell>
                    <rux-table-header-cell></rux-table-header-cell>
                    <rux-table-header-cell>Station</rux-table-header-cell>
                    <rux-table-header-cell>Object</rux-table-header-cell>
                    <rux-table-header-cell>AOS</rux-table-header-cell>
                    <rux-table-header-cell>Duration</rux-table-header-cell>
                    {hasPermission('booking_manage_*') && (
                    <rux-table-header-cell>Cancel</rux-table-header-cell>
                    )}
                </rux-table-header-row>
                {bookings
                    .sort((a, b) => {
                        return (new Date(a.time_aos)).getTime() - (new Date(b.time_aos)).getTime();
                    })
                    .map((b, i) => (
                        <rux-table-row selected="false" key={i}>
                            <rux-table-cell>
                                <Link to={`/bookings/${b.booking_id}`}>
                                    <rux-button secondary icon-only icon="launch" borderless/>
                                </Link>
                            </rux-table-cell>
                            <rux-table-cell>
                            <rux-status status={renderStatusIcon(b)}></rux-status>
                            </rux-table-cell>
                            <rux-table-cell>
                                {b.station.station_name || 'n/a'}
                            </rux-table-cell>
                            <rux-table-cell>{b.object.name  || 'n/a'}
                                <br/>
                                <span style={{
                                    fontSize: '0.8em',
                                    color: "gray"
                                }}>{b.object.norad_id || 'n/a'}</span>
                            </rux-table-cell>
                            <rux-table-cell>
                                {timeToNow(b.time_aos)}
                                <br/>
                                <span style={{
                                    fontSize: '0.8em',
                                    color: "gray"
                                }}>{moment(b.time_aos).utc().format("HH:mm:ss")}</span>
                            </rux-table-cell>
                            <rux-table-cell>
                                {duration(b.time_aos, b.time_los)}
                                <br/>
                                <span style={{fontSize: '0.8em', color: "gray"}}>{b.maximum
                                    ? "max. [" + parseFloat(b.maximum.azimuth).toFixed(0) + "," + parseFloat(b.maximum.elevation).toFixed(0) + "]"
                                    : 'n/a'}</span>  
                            </rux-table-cell>
                            {hasPermission('booking_manage_*') && (
                            <rux-table-cell>
                                <rux-button secondary icon-only icon="remove-circle-outline" borderless
                                            onClick={() => handleDeleteBookingClick(b.booking_id)}/>
                            </rux-table-cell>
                            )}
                        </rux-table-row>
                    ))}

            </rux-table-body>
        </rux-table>

    );
};

export default UpcomingBookings;