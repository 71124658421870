import api from './config/axiosConfig'

const ALERT_API_BASE_URL = 'alerts'

export const getAlerts = async (systems, levels, stations, searchString, offset, limit, group, sendEntries=false) => {
    let queryString = '';

    const params = new URLSearchParams();
    const systemsFiltered = systems?.filter(item => item.selected)
    if (systemsFiltered?.length > 0) {
        params.append('system', systemsFiltered.map(system => system.name).join(","));
    }
    const levelsFiltered = levels?.filter(item => item.selected)
    if (levelsFiltered?.length > 0 ) {
        params.append('level', levelsFiltered.map(level => level.name).join(","));
    }
    const stationsFiltered = stations?.filter(item => item.selected)
    if (stationsFiltered?.length > 0 ) {
        params.append('station_id', stationsFiltered.map(station => station.station_id).join(","));
    }
    if (searchString) {
        params.append('search', searchString.split(" ").join(","))
    }
    if (offset) {
        params.append('offset', offset.toString())
    }
    if (limit) {
        params.append('limit', limit.toString())
    }
    if (group) {
        params.append('group', group.toString())
    }


    if (params.toString()) {
        queryString = `?${params.toString()}`;
    }

    const response = await api.get(`${ALERT_API_BASE_URL}${queryString}`)

    return sendEntries ? response : response.data;
}

export const acknowledgeAlert = (data) => {
    return api.post(ALERT_API_BASE_URL, data)
}