import React, {useState, useEffect, useCallback} from 'react';
import {
    RuxButton,
    RuxTable,
    RuxTableHeaderRow,
    RuxTableHeaderCell,
    RuxTableRow,
    RuxTableCell,
    RuxDialog,
    RuxTableBody, RuxTableHeader
} from '@astrouxds/react'; // Import only the necessary components
import * as api from '../../apis/profileApi';
import ProfileForm from './components/ProfileForm';

const ProfileManagement = ({objectId}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loadedProfiles, setLoadedProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [profileFormData, setProfileFormData] = useState({
        title: '',
        assigned_object_id: null,
        center_rx_frequency: '',
        rx_bandwidth: '',
        polarization: '',
        note: '',
        uplink_enabled: false,
        downlink_enabled: false,
        params: ''
    });

    const loadProfiles = useCallback(async () => {
        try {
            const profilesData = await api.getProfilesByObjectId(objectId) || [];
            setLoadedProfiles(profilesData);
        } catch (error) {
            console.error('Error loading profiles:', error);
        }
    }, [objectId]);

    useEffect(() => {
        if (objectId) {
            loadProfiles();
            const interval = setInterval(loadProfiles, 10000); // Update profiles every 10 seconds

            return () => clearInterval(interval); // Clear interval on unmount
        }
    }, [objectId, loadProfiles]);

    const handleProfilesUpdated = useCallback(() => {
        loadProfiles();
    }, [loadProfiles]);

    const handleEditProfileClick = useCallback((profile_id) => {
        const selectedProfile = loadedProfiles.find((profile) => profile.profile_id === profile_id);
        setIsDialogOpen(true);
        setSelectedProfile(selectedProfile);
    }, [loadedProfiles]);

    const handleRemoveProfileClick = useCallback(async (profileId) => {
        try {
            await api.deleteProfile(profileId);
            setLoadedProfiles(prevProfiles => prevProfiles.filter(profile => profile.profile_id !== profileId));
        } catch (error) {
            console.error('Error removing profile:', error);
        }
    }, []);

    const handleAddProfileClick = useCallback(() => {
        const assigned_object_id = objectId;
        setIsDialogOpen(true);
        setSelectedProfile(null);
        setProfileFormData((prevData) => ({
            ...prevData,
            assigned_object_id,
        }));
    }, [objectId]);

    const handleProfileDialogClose = useCallback(() => {
        setIsDialogOpen(false);
        loadProfiles();
    }, [loadProfiles]);

    const determineUpDown = (profile) => {
        if(profile.uplink_enabled && profile.downlink_enabled) {
            return "UP/DOWN"
        } else if(profile.uplink_enabled) {
            return "UP"
        } else if(profile.downlink_enabled) {
            return "DOWN"
        } else {
            return "-/-"
        }
    }

    return (
        <div>
            <rux-card style={{width: '100%'}}>
                <div slot="header" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span>Profiles</span>
                    <RuxButton secondary="" id="open" onClick={handleAddProfileClick}>
                        Add Profile
                    </RuxButton>
                </div>
                <RuxTable>
                    <RuxTableHeader>
                        <RuxTableHeaderRow style={{backgroundColor: 'unset'}}>
                            <RuxTableHeaderCell>Title</RuxTableHeaderCell>
                            <RuxTableHeaderCell>Up/Down</RuxTableHeaderCell>
                            <RuxTableHeaderCell>Note</RuxTableHeaderCell>
                            <RuxTableHeaderCell></RuxTableHeaderCell>
                        </RuxTableHeaderRow>
                    </RuxTableHeader>
                    <RuxTableBody>
                        {loadedProfiles.length <= 0 ? (
                            <RuxTableRow>
                                <td colSpan="7" style={{textAlign: 'center', verticalAlign:"middle", height:"40px"}}>
                                    No profiles
                                </td>
                            </RuxTableRow>
                        ) : (loadedProfiles.map((profile, index) => (
                                <RuxTableRow key={profile.id}>
                                    <RuxTableCell>{profile.title}</RuxTableCell>
                                    <RuxTableCell>{determineUpDown(profile)}</RuxTableCell>
                                    <RuxTableCell>{profile.note}</RuxTableCell>
                                    <RuxTableCell style={{textAlign: 'right'}}>
                                        <RuxButton
                                            size="small"
                                            secondary
                                            icon-only
                                            icon="create"
                                            borderless
                                            onClick={() => handleEditProfileClick(profile.profile_id)}
                                        />
                                        <RuxButton
                                            size="small"
                                            secondary
                                            icon-only
                                            icon="remove-circle-outline"
                                            borderless
                                            onClick={() => handleRemoveProfileClick(profile.profile_id)}
                                        />
                                    </RuxTableCell>
                                </RuxTableRow>
                            ))
                        )}
                    </RuxTableBody>
                </RuxTable>
            </rux-card>

            {
                isDialogOpen && (
                    <RuxDialog id="profile-dialog" open={isDialogOpen} onRuxclosed={handleProfileDialogClose}>
                        <ProfileForm
                            selectedProfile={selectedProfile}
                            onProfilesUpdated={handleProfilesUpdated}
                            setIsDialogOpen={setIsDialogOpen}
                            profileFormData={profileFormData}
                            setProfileFormData={setProfileFormData}
                        />
                    </RuxDialog>
                )
            }
        </div>
    )
        ;
};

export default ProfileManagement;