import api from './config/axiosConfig';

const BOOKING_API_BASE_URL = 'scheduling/bookings';

export const createBooking = (data) => {
    return api.post(BOOKING_API_BASE_URL, data);
};

export const getBookings = async (timeFrom, timeTo, offset, limit, sendEntries=false) => {
    let queryString = '';

    const params = new URLSearchParams();
    if (timeFrom) {
        params.append('time_from', timeFrom);
    }
    if (timeTo) {
        params.append('time_to', timeTo);
    }
    if (offset) {
        params.append('offset', offset.toString())
    }
    if (limit) {
        params.append('limit', limit.toString())
    }

    if (params.toString()) {
        queryString = `?${params.toString()}`;
    }

    const response = await api.get(`${BOOKING_API_BASE_URL}${queryString}`)

    return sendEntries ? response : response.data;
};


export const getBooking = (bookingId) => {
    const url = `${BOOKING_API_BASE_URL}/${bookingId}`;
    return api.get(url);
};


export const deleteBooking = (bookingId) => {
    const url = `${BOOKING_API_BASE_URL}/${bookingId}`;
    return api.delete(url);
};
