import React, { useState, useEffect } from 'react';
import AccountTable from '../systems/userManagement/AccountTable';
import UserTable from '../systems/userManagement/UserTable';
import AccountObjects from '../systems/userManagement/AccountObjects';
import AccountStations from '../systems/userManagement/AccountStations';
import { useAuth } from "../auth/AuthContext";
import * as accountApi from '../apis/accountApi';

function UserManagementScreen() {
  const { user } = useAuth();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const loadedAccounts = await accountApi.getAccounts() || [];

        if (user) {
          const userAccount = loadedAccounts.find((account) => account.account_id === user.account_id);
          setSelectedAccount(userAccount);
        }
      } catch (error) {
        console.error('Error loading accounts:', error);
      }
    };

    loadAccounts();
  }, [user]);

  const handleAccountSelect = async (account, usersForAccount) => {
    setSelectedAccount(account);
    setUsers(usersForAccount);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
        <div style={{ width: '32%' }}>

          <div>
            <AccountTable onSelectAccount={handleAccountSelect} />
          </div>

          <div style={{ marginTop: '4%' }}>
            <UserTable selectedAccount={selectedAccount} users={users} setUsers={setUsers} />
          </div>
          
        </div>

        <div style={{ width: '32%' }}>
          <AccountObjects selectedAccount={selectedAccount} />
        </div>

        <div style={{ width: '32%' }}>
          <AccountStations selectedAccount={selectedAccount} />
        </div>

      </div>
    </div>

  );
}

export default UserManagementScreen;