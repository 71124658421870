import api from './config/axiosConfig';

const STATION_API_BASE_URL = 'stations';

export const createStation = (data) => {
    return api.post(STATION_API_BASE_URL, data);
};

export const getStations = () => {
    return api.get(STATION_API_BASE_URL);
};

export const getStation = (stationId) => {
    const url = `${STATION_API_BASE_URL}/${stationId}`;
    return api.get(url);
};

export const updateStation = (stationId, data) => {
    const url = `${STATION_API_BASE_URL}/${stationId}`;
    return api.put(url, data);
};

export const deleteStation = (stationId) => {
    const url = `${STATION_API_BASE_URL}/${stationId}`;
    return api.delete(url);
};