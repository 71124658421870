import React, { useState, useEffect } from 'react';
import {
  RuxButton,
  RuxTable,
  RuxTableHeaderRow,
  RuxTableHeaderCell,
  RuxTableHeader,
  RuxTableRow,
  RuxTableCell,
  RuxCard,
  RuxCheckbox,
  RuxDialog,
  RuxTableBody
} from '@astrouxds/react';
import * as accountApi from '../../apis/accountApi';
import * as stationApi from '../../apis/stationApi';
import { useAuth } from '../../auth/AuthContext';
import { notify } from "../../libs/notificationSystem/notificationManager";

const AccountStations = ({ selectedAccount }) => {
  const [stations, setStations] = useState([]);
  const [assignedStations, setAssignedStations] = useState([]);
  const [selectedStationIds, setSelectedStationIds] = useState([]);
  const [isAssignStationDialogOpen, setIsAssignStationDialogOpen] = useState(false);
  const { hasPermission } = useAuth();
  const [allStations, setAllStations] = useState([]);

  console.log(allStations);

  const loadAllStations = async () => {
    try {
      const allStations = await stationApi.getStations();
      allStations.sort((a, b) => a.station_name.localeCompare(b.station_name));
      console.log(allStations)
      setAllStations(allStations);
    } catch (error) {
      console.error('Error loading all stations:', error);
    }
  };

  useEffect(() => {
    const loadAssignedStations = async () => {
      try {
        if (selectedAccount) {
          const response = await accountApi.getAccounts();
          const allAccounts = Array.isArray(response) ? response : [];
          
          const selectedAccountInfo = allAccounts.find(
            (acc) => acc.account_id === selectedAccount.account_id
          );

          console.log(selectedAccountInfo)
          
          if (selectedAccountInfo) {
            console.log(selectedAccountInfo)
            const assignedStationsForSelectedAccount = selectedAccountInfo.stations;
            setAssignedStations(assignedStationsForSelectedAccount);
  
            const unassignedStations = allStations.filter(
              (station) => !assignedStationsForSelectedAccount.some((assignedStation) => assignedStation.station_id === station.station_id)
            );
            setStations(unassignedStations);
          }
        }
      } catch (error) {
        console.error('Error loading assigned stations:', error);
      }
    };
  
    if (isAssignStationDialogOpen || selectedAccount) {
      loadAssignedStations();
    }
  }, [selectedAccount, isAssignStationDialogOpen, allStations]);

  const openAssignStationDialog = async () => {
    setSelectedStationIds([]);
    setIsAssignStationDialogOpen(true);
    await loadAllStations();
  };

  const closeAssignStationDialog = () => {
    setIsAssignStationDialogOpen(false);
  };

  const handleCheckboxChange = (stationId) => {
    setSelectedStationIds((prevSelectedStationIds) => {
      if (prevSelectedStationIds.includes(stationId)) {
        return prevSelectedStationIds.filter((id) => id !== stationId);
      } else {
        return [...prevSelectedStationIds, stationId];
      }
    });
  };

  const handleRemoveStation = async (stationId) => {
    try {
      const updatedStations = assignedStations.filter((station) => station.station_id !== stationId);
  
      const data = {
        account_id: selectedAccount.account_id,
        account_name: selectedAccount.account_name,
        stations: updatedStations.map((station) => ({ station_id: station.station_id })),
      };
  
      const response = await accountApi.updateAccount(selectedAccount.account_id, data);
      console.log(response);
  
      setAssignedStations(updatedStations);
      notify(`Station unassigned from account`, 'normal');
    } catch (error) {
      console.error('Error unassigning station from account:', error);
      notify(`Error unassigning station with ID ${stationId} from account.`, 'critical');
    }
  };

  const handleAssignStations = async () => {
    try {
      if (!selectedAccount) {
        console.error('No account selected');
        return;
      }
  
      const data = {
        account_id: selectedAccount.account_id,
        account_name: selectedAccount.account_name,
        stations: [
          ...assignedStations.map(station => ({ station_id: station.station_id })),
          ...selectedStationIds.map(stationId => ({ station_id: stationId })),
        ],
      };
  
      const response = await accountApi.updateAccount(selectedAccount.account_id, data);
      console.log(response);
      notify('Stations assigned to account', 'normal');
      closeAssignStationDialog();
    } catch (error) {
      console.error('Error assigning stations to account:', error);
      notify('Error assigning stations to account.', 'critical');
    }
  };

  return (
    <div>
      {hasPermission('station_manage_all') && (
    <RuxCard style={{ width: '100%' }}>
      
      <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{`Stations for ${selectedAccount ? `${selectedAccount.account_name} Account` : 'Account'}`}</span>
        <RuxButton secondary="" id="assign-station" onClick={openAssignStationDialog}>
          Assign Station
        </RuxButton>
      </div>
   
      <RuxTable>
        <RuxTableHeader>
          <RuxTableHeaderRow>
            <RuxTableHeaderCell>Station Name</RuxTableHeaderCell>
            <RuxTableHeaderCell></RuxTableHeaderCell>
          </RuxTableHeaderRow>
        </RuxTableHeader>
        <RuxTableBody>
        {assignedStations && assignedStations.length > 0 ? (
          assignedStations.map((station) => (
            <RuxTableRow key={station.station_id} style={{ cursor: 'pointer' }}>
              <RuxTableCell>{station.station_name}</RuxTableCell>
              <RuxTableCell style={{ textAlign: 'right' }}>
                <RuxButton
                  size="small"
                  secondary
                  icon-only
                  icon="remove-circle-outline"
                  borderless
                  onClick={() => handleRemoveStation(station.station_id)}
                />
              </RuxTableCell>
            </RuxTableRow>
          ))
        ) : (
          <RuxTableRow>
            <RuxTableCell>No stations assigned</RuxTableCell>
          </RuxTableRow>
        )}
        </RuxTableBody>
      </RuxTable>

      <RuxDialog id="assign-station-dialog" open={isAssignStationDialogOpen} onRuxclosed={closeAssignStationDialog}>
        <span slot="header">Assign Stations</span>
        <div>
          {stations && stations.length > 0 ? (
            stations.map((station) => (
              <div key={station.station_id} style={{ display: 'flex', alignItems: 'center' }}>
                <RuxCheckbox
                  style={{ marginRight: '10px' }}
                  name={`station-checkbox-${station.station_id}`}
                  label={station.station_name} 
                  onRuxchange={() => handleCheckboxChange(station.station_id)} 
                  checked={selectedStationIds.includes(station.station_id)} 
                />
              </div>
            ))
          ) : (
            <div>No stations available to assign</div>
          )}
        </div>
        <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <RuxButton id="cancel" secondary="" onClick={closeAssignStationDialog}>
            Cancel
          </RuxButton>
          {stations && stations.length > 0 ?(
          <RuxButton id="confirm" onClick={handleAssignStations}>
            Confirm
          </RuxButton>
              ) : ''
          }
        </div>
      </RuxDialog>
    </RuxCard>
    )}
    </div>
  );
};

export default AccountStations;