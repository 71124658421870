import React from "react";
import UniversalComponent from "../commands/passplan/UniversalComponent";
import { useAuth } from "../../auth/AuthContext";

const StationCommands = ({ station }) => {
    const { hasPermission } = useAuth();

    const isFromStation = true;

    const loadTemplateCommandsCaller = async () => {
        try {
            console.log('loadTemplateCommandsCaller function invoked!');

        } catch (error) {
            console.error('Error in loadTemplateCommandsCaller:', error);
        }
    };

    const executeButtonClickCaller = async () => {
        try {
            console.log('executeButtonClickCaller function invoked!');

        } catch (error) {
            console.error('Error in executeButtonClickCaller:', error);
        }
    };

    const runAllCommandsCaller = async () => {
        try {
            console.log('runAllCommandsCaller function invoked!');

        } catch (error) {
            console.error('Error in runAllCommandsCaller:', error);
        }
    };

    const displayStatus = async () => {
        try {
            console.log('displayStatus function invoked!');

        } catch (error) {
            console.error('Error in displayStatus:', error);
        }
    };

    return ( hasPermission("station_commands_*") &&
        <div>
            <UniversalComponent
                isFromStation={isFromStation}
                station={station}
                loadTemplateCommandsCaller={loadTemplateCommandsCaller}
                displayStatus={displayStatus}
                runAllCommandsCaller={runAllCommandsCaller}
                executeButtonClickCaller={hasPermission('station_manage_all') ? (item, index) => executeButtonClickCaller(item, index, true) : null}
                label="Station Commands"
            />
        </div>
    );
};

export default StationCommands;