import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import { useAuth } from './auth/AuthContext';
import ObjectManagementScreen from './screens/ObjectManagementScreen';
import StationManagementScreen from './screens/StationManagementScreen';
import StationDetailScreen from './screens/StationDetailScreen';
import ScheduleScreen from './screens/ScheduleScreen';
import HeaderGlobal from './components/HeaderGlobal';
import { NotificationProvider } from './libs/notificationSystem/NotificationProvider';
import BookingDetailScreen from './screens/BookingDetailScreen';
import BookingsScreen from './screens/BookingsScreen';
import UserManagementScreen from './screens/UserManagementScreen';
import MapScreen from './screens/MapScreen';
import TemplateManagementScreen from './screens/TemplateManagementScreen';
import SandboxScreen from './screens/SandboxScreen';
import AlertsScreen from './screens/AlertsScreen';

function App() {
    const { isAuthenticated } = useAuth();

    return (
        <div>
            <HeaderGlobal />
            <NotificationProvider>
                <Routes>
                    {!isAuthenticated ? (
                        <>
                            <Route
                                path="*"
                                element={<LoginScreen />} />
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<ScheduleScreen />} />
                            <Route path="/objects" element={<ObjectManagementScreen />} />
                            <Route path="/stations" element={<StationManagementScreen />} />
                            <Route path="/stations/:id?" element={<StationDetailScreen />} />
                            <Route path="/templates/:id?" element={<TemplateManagementScreen />} />
                            <Route path="/sandbox" element={<SandboxScreen />} />
                            <Route path="/schedule" element={<ScheduleScreen />} />
                            <Route path="/bookings" element={<BookingsScreen />} />
                            <Route path="/bookings/:id?" element={<BookingDetailScreen />} />
                            <Route path="/user_management" element={<UserManagementScreen />} />
                            <Route path="/map" element={<MapScreen />} />
                            <Route path="/alerts" element={<AlertsScreen />} />
                        </>
                    )}
                </Routes>
            </NotificationProvider>
        </div>
    );
}

export default App;