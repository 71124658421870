import React from 'react';
import {
    RuxButton,
    RuxIcon
} from "@astrouxds/react";

export default function Pagination({pageInfo, setPageInfo, totalEntries}) {

    const handleButtonClick = (button) => {
        if(button === "left") {
            setPageInfo(oldPageInfo => ({
                ...oldPageInfo,
                page: oldPageInfo.page - 1
            }))
        } else if(button === "right") {
            setPageInfo(oldPageInfo => ({
                ...oldPageInfo,
                page: oldPageInfo.page + 1
            }))
        }
    }

    return (
        <div style={{float: 'right', display: 'table'}}>
            <span style={{display: 'table-cell', verticalAlign: 'middle'}}>
                {Math.min(pageInfo.page * pageInfo.perPage + 1, totalEntries)} - { Math.min((pageInfo.page + 1) * pageInfo.perPage, totalEntries) } of {totalEntries}
            </span>
            <RuxButton onClick={() => handleButtonClick("left")} borderless disabled={pageInfo.page === 0}><RuxIcon icon='keyboard-arrow-left' /></RuxButton>
            <RuxButton onClick={() => handleButtonClick("right")} borderless disabled={(pageInfo.page + 1) * pageInfo.perPage >= totalEntries}><RuxIcon icon='keyboard-arrow-right' /></RuxButton>
        </div>
    )
}