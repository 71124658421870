import React from 'react';

const StatusIndicator = ({ level, title }) => {
    const statusColor = {
        normal: '#56f000', // Green
        standby: '#2dccff', // Yellow
        caution: '#fce83a', // Orange
        serious: '#ffb302', // Red
        critical: '#ff3838', // Dark Red
    }[level] || '#a4abb6'; // Default to black if level is not recognized

    return (
        <span style={{ display: 'flex' }}>
            <rux-status status={level}></rux-status>
            <span style={{ marginLeft: '1em', color: statusColor }}>
                {String(title ? title : level).toUpperCase()}
            </span>
        </span>
    );
};

export default StatusIndicator;