import React, {useEffect} from 'react';
import BookingsTable from "../systems/schedule/components/BookingsTable";

const BookingsScreen = (props) => {
    useEffect(() => {
        document.title = "GCN Bookings";
    }, []);

    return (
        
        <div style={{
            display: 'grid',
            gridTemplateColumns: '100%',
            justifyContent: 'space-evenly',
            margin: '12px',
            gap: '12px'
        }}>
            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    <div slot="header">Bookings</div>
                    <BookingsTable/>
                </rux-container>
            </div>
        </div>
    );
}

export default BookingsScreen;
