import React, { useState, useEffect } from 'react';
import {
    RuxButton
} from '@astrouxds/react';
import { notify } from "../libs/notificationSystem/notificationManager";
import TemplateTable from '../systems/commands/passplan/TemplateTable'
import TemplateManagement from '../systems/commands/passplan/TemplateManagement';
import { useAuth } from "../auth/AuthContext";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getCommandTemplates } from '../apis/commandApi';

const TemplateManagementScreen = () => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
    const { hasPermission } = useAuth();
    const { id } = useParams()
    const navigate = useNavigate()
    
    useEffect(() => {
        document.title = "GCN Templates";

        const loadCommandTemplates = async () => {
            try {
                const templates = await getCommandTemplates() || [];
                setSelectedTemplate(templates.find(template => template.template_id === id) || navigate('/templates'));
            } catch (error) {
                console.error('Error loading command templates:', error);
            }
        };

        if(id) {
            loadCommandTemplates()
        } else {
            setSelectedTemplate(null)
        }
    }, [id]);

    const handleCreateTemplate = async () => {
        try {
            setIsCreatingTemplate(true);
            setSelectedTemplate(null);
            
            notify('Creating new template. Fill in details and save.', 'caution');
            navigate('/templates')
        } catch (error) {
            notify('Error creating template', 'error');
        }
    };

    const handleEditTemplate = (selectedTemplate) => {
        console.log('Selected template in TemplateManagementScreen:', selectedTemplate);
        setSelectedTemplate(selectedTemplate);
        setIsCreatingTemplate(false);
        navigate(selectedTemplate ? `/templates/${selectedTemplate.template_id}` : '/templates')
    };

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: '33% 66%', justifyContent: 'space-evenly', padding: '10px 0' }}>
                <rux-card style={{ gridColumn: 1 }}>
                    <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>Templates</div>
                        {hasPermission('template_manage_all') && (
                        <RuxButton secondary="" id="open" onClick={handleCreateTemplate}>Create Template</RuxButton>
                        )}
                    </div>
                    <TemplateTable
                        handleEditTemplate={handleEditTemplate}
                        selectedTemplate={selectedTemplate}
                    />
                </rux-card>

                <TemplateManagement
                    style={{ gridColumn: 2 }}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    handleCreateTemplate={handleCreateTemplate}
                    isCreatingTemplate={isCreatingTemplate}
                    setIsCreatingTemplate={setIsCreatingTemplate}
                    handleEditTemplate={handleEditTemplate}
                /> 
            </div>
        </div>
    );
};

export default TemplateManagementScreen;