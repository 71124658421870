import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import SatelliteObject from './SatelliteObject';
import { world } from './world';

const WorldMap = ({ stations, objects, selectedObjects, selectedStations, handleObjectPathChange, objectPathStates }) => {
  const component_id = `world_map_${new Date().getTime()}`;
  const object_dimension = 8;
  const station_dimension = 8;

  const chartReference = useRef(null);
  const projection = useRef(null);
  const graticule = useRef(null);
  const outline = useRef(null);
  const path = useRef(null);
  const chart = useRef(null);

  const [defaultPathOpts] = useState({
    minutes_before: 60,
    minutes_after: 60,
    step_seconds: 1,
    color: 'aqua',
  });

  useEffect(() => {
    drawChart();
  }, []);

  useEffect(() => {
    clearObjects();
    clearStations();

    if (stations) {
      stations.forEach((s) => {
        if (selectedStations.includes(String(s.station_id))) {
          drawStation(s.location.latitude, s.location.longitude, s.station_name);
        }
      });
    }

    if (objects) {
      objects.forEach((o) => {
        const satelliteObject = SatelliteObject({
          norad_id: o.norad_id,
          tle: o.tle,
          name: o.name,
        });

        if (selectedObjects.includes(String(o.norad_id))) {
          drawObject(satelliteObject);
        }
      });
    }
  }, [stations, objects, selectedObjects, selectedStations]);

  useEffect(() => {
    console.log('Object Path States:', objectPathStates);
    clearObjectPaths();
    if (objects) {
      objects.forEach((o) => {
        const satelliteObject = SatelliteObject({
          norad_id: o.norad_id,
          tle: o.tle,
          name: o.name,
        });

        if (objectPathStates[o.norad_id]) {
          drawObjectPath(satelliteObject);
        }
      });
    }
  }, [objectPathStates]);

  const drawObjectPath = (satelliteObject, time = null, opts = {}) => {
    if (time === null) {
      time = new Date();
    }
    opts = { ...defaultPathOpts, ...opts };

    const time_start = time.getTime() - opts.minutes_before * 60 * 1000;
    const time_end = time.getTime() + opts.minutes_after * 60 * 1000;

    let points = [];
    for (let calcTime = time_start; calcTime < time_end; calcTime += opts.step_seconds * 1000) {
      const time_to_calc = new Date(calcTime);
      points.push(satelliteObject.getPositionAtTime(time_to_calc));
    }

    for (let i in points) {
      const point = points[i];
      chart.current
        .append('circle')
        .data([point])
        .attr('class', 'object-path')
        .attr('r', 0.2)
        .attr('fill', opts.color)
        .attr('cx', (d) => projection.current([d.longitude, d.latitude])[0])
        .attr('cy', (d) => projection.current([d.longitude, d.latitude])[1]);
    }
  };

  const drawObject = (satelliteObject, opts = { color: 'yellow', opacity: 0.7 }) => {
    const norad_id = satelliteObject.norad_id;

    const positionAtTime = satelliteObject.getPositionAtTime(new Date());
    if (!positionAtTime) {
      console.error('Error getting position for norad_id:', norad_id);
      return null;
    }

    const lat = positionAtTime.latitude;
    const long = positionAtTime.longitude;
    const title = satelliteObject.getName();

    const objectGroup = chart.current
      .append('g')
      .data([{ lat: lat, long: long }])
      .attr('class', 'object-group');

    const objectRect = objectGroup
      .append('rect')
      .attr('width', object_dimension)
      .attr('height', object_dimension)
      .attr('stroke', 'black')
      .attr('fill', opts.color)
      .attr('opacity', opts.opacity)
      .attr('x', projection.current([long, lat])[0] - object_dimension / 2)
      .attr('y', projection.current([long, lat])[1] - object_dimension / 2)
      .on('mouseenter', function () {
        if (selectedObjects.includes(String(norad_id))) {
          objectText.attr('opacity', 1);
        } else {
          objectText.attr('opacity', 0.5);
        }
      })
      .on('mouseleave', function () {
        objectText.attr('opacity', selectedObjects.includes(String(norad_id)) ? 1 : 0.2);
      });

    const objectText = objectGroup
      .append('text')
      .text(title)
      .attr('x', (d) => projection.current([d.long, d.lat])[0])
      .attr('y', (d) => projection.current([d.long, d.lat])[1] - object_dimension / 2 - 8)
      .attr('text-anchor', 'middle')
      .attr('font-size', '1em')
      .attr('color', opts.color)
      .attr('opacity', selectedObjects.includes(String(norad_id)) ? 1 : 0.4);

    return objectGroup;
  };

  const drawStation = (lat, long, title, color = 'red') => {
    const stationGroup = chart.current
      .append('g')
      .data([{ lat: lat, long: long }])
      .attr('class', 'station-group')
      .attr('opacity', 0.6);

    const stationRect = stationGroup
      .append('rect')
      .attr('width', station_dimension)
      .attr('height', station_dimension)
      .attr('stroke', 'black')
      .attr('fill', color)
      .attr('x', projection.current([long, lat])[0] - station_dimension / 2)
      .attr('y', projection.current([long, lat])[1] - station_dimension / 2)
      .on('mouseenter', function () {
        stationText.attr('opacity', 1);
      })
      .on('mouseleave', function () {
        stationText.attr('opacity', 1);
      });

    const stationText = stationGroup
      .append('text')
      .text(title)
      .attr('x', (d) => projection.current([d.long, d.lat])[0])
      .attr('y', (d) => projection.current([d.long, d.lat])[1] - station_dimension - 5)
      .attr('fill', color)
      .attr('text-anchor', 'middle')
      .attr('font-size', '1em')
      .attr('opacity', 0.8);

    return stationGroup;
  };

  const drawChart = () => {
    console.log('Selected Stations in WorldMap:', selectedStations);
    const land = topojson.feature(world, world.objects.land);

    const parentElement = d3.select('#' + component_id);
    parentElement.html('');

    const width = parseFloat(parentElement.node().getBoundingClientRect().width).toFixed(0);

    const screen = { width: width, height: width / 2 };
    const sphere = { type: 'Sphere' };

    projection.current = d3
      .geoEquirectangular()
      .translate([screen.width / 2, screen.height / 2])
      .fitExtent([[1, 1], [screen.width - 1, screen.height - 1]], sphere)
      .rotate([-10, 0])
      .precision(0.1);

    graticule.current = d3.geoGraticule().stepMinor([15, 10])();
    outline.current = d3.geoCircle().radius(180).center([0, 90])();
    path.current = d3.geoPath(projection.current);

    const yAxis = (g) =>
      g.call(
        g =>
          g
            .append('g')
            .selectAll('text')
            .data(d3.range(10, 81, 10))
            .join('text')
            .attr('dy', '0.35em')
            .text((d) => `${d}°`)
            .datum((d) => projection.current([-180, d]))
            .attr('x', ([x]) => x)
            .attr('y', ([, y]) => y)
      );

    const xAxis = (g) =>
      g.call(
        g =>
          g
            .append('g')
            .attr('stroke', 'currentColor')
            .selectAll('line')
            .data(d3.range(0, 360, 5))
            .join('line')
            .datum((d) => [
              projection.current([d, 0]),
              projection.current([d, d % 60 ? -1 : -2]),
            ])
            .attr('x1', ([[x1]]) => x1)
            .attr('x2', ([, [x2]]) => x2)
            .attr('y1', ([[, y1]]) => y1)
            .attr('y2', ([, [, y2]]) => y2)
      ).call(
        (g) =>
          g
            .append('g')
            .selectAll('text')
            .data(d3.range(0, 360, 15))
            .join('text')
            .attr('dy', '0.35em')
            .text((d) => `${d}°`)
            .attr('font-size', (d) => (d % 360 ? null : 14))
            .attr('font-weight', (d) => (d % 360 ? null : 'bold'))
            .datum((d) => projection.current([d, -4]))
            .attr('x', ([x]) => x)
            .attr('y', ([, y]) => y)
      );

    chart.current = parentElement
      .append('svg')
      .attr('viewBox', [0, 0, screen.width, screen.height])
      .attr('font-family', 'sans-serif')
      .attr('font-size', 10)
      .attr('text-anchor', 'middle')
      .attr('fill', 'currentColor')
      .style('margin', '0')
      .style('color', 'gray')
      .style('display', 'block');

    chart.current
      .append('path')
      .attr('d', path.current(land))
      .attr('fill', 'black');

    chart.current
      .append('path')
      .attr('d', path.current(graticule.current))
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .attr('stroke-opacity', 0.2);

    chart.current
      .append('path')
      .attr('d', path.current(outline.current))
      .attr('fill', 'none')
      .attr('stroke', 'currentColor');

    chart.current.append('g').call(xAxis);

    chart.current.append('g').call(yAxis);

    chart.current
      .append('foreignObject')
      .attr('x', 10)
      .attr('y', 10)
      .attr('width', 200)
      .attr('height', 100)
  };

  const clearObjects = () => {
    chart.current.selectAll('.object-group').remove();
  };

  const clearStations = () => {
    chart.current.selectAll('.station-group').remove();
  };

  const clearObjectPaths = () => {
    chart.current.selectAll('.object-path').remove();
  };

  return (
    <div style={{}}>
      <div ref={chartReference} id={component_id}></div>
      <div style={{ position: 'absolute', top: 0, left: 5, zIndex: 1 }}>
      </div>
    </div>
  );
};

export default WorldMap;