import api from './config/axiosConfig';

const USER_API_BASE_URL = 'auth/register';

export const createUser = (data) => {
  return api.post(USER_API_BASE_URL, data);
};

export const getUsersByAccountId = (accountId) => {
  const url = `${USER_API_BASE_URL}/?account_id=${accountId}`;
  return api.get(url);
};

export const editUser = (userId, data) => {
    const url = `${USER_API_BASE_URL}/${userId}`;
    return api.patch(url, data);
};

export const deleteUser = (userId) => {
    const url = `${USER_API_BASE_URL}/${userId}`;
    return api.delete(url);
};