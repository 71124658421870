import React, { useState, useEffect } from 'react';
import { getOpenTransmissions, stopTransmission, activateTransmission, transmitData } from '../../apis/transmissionApi';
import { getProfilesByObjectId } from '../../apis/profileApi';
import { RuxTable, RuxTableHeader, RuxTableHeaderRow, RuxTableBody, RuxTableRow, RuxTableCell, RuxTableHeaderCell, RuxButton, RuxDialog, RuxInput, RuxSelect, RuxOption } from '@astrouxds/react';
import { useAuth } from "../../auth/AuthContext";
import { Link } from "react-router-dom";
import { notify } from "../../libs/notificationSystem/notificationManager";

const TransmissionTable = ({ booking, label }) => {
    const [transmissions, setTransmissions] = useState([]);
    const { hasPermission } = useAuth();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [transmissionFormData, setTransmissionFormData] = useState({
        booking_id: '',
        comm_profile_id: ''
    });
    const [profiles, setProfiles] = useState([])

    useEffect(() => {
        const handleLoadTransmissions = async () => {
            if (!hasPermission('transmission_manage_all')) return;
            try {
                if (!booking) {
                    const transmissionsData = await getOpenTransmissions();
                    setTransmissions(transmissionsData);
                } else {
                    const transmissionsData = await getOpenTransmissions();
                    const filteredTransmissions = transmissionsData.filter(transmission => transmission.booking_id === booking.booking_id);
                    setTransmissions(filteredTransmissions);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        handleLoadTransmissions();

        const handleLoadProfiles = async () => {
            if(!hasPermission("object_profile_view_all")) return
            try {
                if(booking) {
                    const profileData = await getProfilesByObjectId(booking.object.object_id)
                    setProfiles(profileData)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }
        handleLoadProfiles()
        
        const interval = setInterval(() => {
            handleLoadTransmissions()
            isDialogOpen && handleLoadProfiles()
        }, 1000);
        return () => clearInterval(interval);
    }, [booking, hasPermission, isDialogOpen]);

    const handleDeleteObjectClick = async (transmission_id) => {
        if (!hasPermission('transmission_manage_all')) return;

        try {
            await stopTransmission(transmission_id);
            console.log('Transmission stopped successfully');
            notify(`Transmission stopped successfully`, 'normal');
        } catch (error) {
            console.error(`Error stopping transmission: ${error.message}`);
            notify(`Error stopping transmission: ${error.message}`, 'critical');
        }
    };

    const openActivateTransmissionDialog = () => {
        setTransmissionFormData({
            booking_id: booking?.booking_id || '',
            comm_profile_id: ''
        });
        setIsDialogOpen(true);
    };

    const closeActivateTransmissionDialog = () => {
        setIsDialogOpen(false)
    };

    const handleTransmissionFormChange = (e) => {
        const { name, value } = e.target;
        setTransmissionFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleActivateTransmissionConfirmClick = async () => {
        try {
            await activateTransmission(transmissionFormData);
            notify('Transmission activated successfully', 'normal');
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Error activating transmission:', error);
            notify('Error activating transmission.', 'critical');
        }
    };

    const transformSizeInBytes = (size) => {
        if(size == undefined) {
            return 'N/A'
        } else if(size < 1000) {
            return `${size}B`
        } else if(size < 1000000) {
            return `${(size/1000).toPrecision(3)}kB`
        } else {
            return `${(size/1000000).toPrecision(3)}MB`
        }
    }

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '100%',
            justifyContent: 'space-evenly',
            margin: '12px',
            gap: '12px'
        }}>
            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>{label}</div>
                        <RuxButton onClick={openActivateTransmissionDialog} secondary="">Activate Transmission</RuxButton>
                    </div>
                    <RuxTable>
                        <RuxTableBody>
                            <RuxTableHeaderRow selected={false}>
                                <RuxTableHeaderCell>Booking</RuxTableHeaderCell>
                                <RuxTableHeaderCell>Transmission ID</RuxTableHeaderCell>
                                <RuxTableHeaderCell>Size</RuxTableHeaderCell>
                                <RuxTableHeaderCell></RuxTableHeaderCell>
                            </RuxTableHeaderRow>
                            {transmissions.map(item => (
                                <RuxTableRow key={item.transmission_id}>
                                    <RuxTableCell>
                                        <Link to={`/bookings/${item.booking_id}`}>
                                            <rux-button secondary icon-only icon="launch" borderless />
                                        </Link>
                                    </RuxTableCell>
                                    <RuxTableCell>
                                        {item.transmission_id}
                                    </RuxTableCell>
                                    <RuxTableCell>
                                        {transformSizeInBytes(item.size)}
                                    </RuxTableCell>
                                    <RuxButton size="small" secondary icon-only icon="remove-circle-outline"
                                        borderless
                                        onClick={() => handleDeleteObjectClick(item.transmission_id)} />
                                </RuxTableRow>
                            ))}
                        </RuxTableBody>
                    </RuxTable>
                </rux-container>

            </div>

            <RuxDialog id="assign-object-dialog" open={isDialogOpen} onRuxclosed=''>
                <span slot="header">Activate Transmission</span>
                <div>
                    {booking ? (
                        <>
                        <div>
                            <label>Booking</label><br />
                            <span>{booking.booking_id}</span>
                        </div>
                        <RuxSelect 
                            style={{marginTop: '15px'}}
                            size="small" 
                            name="comm_profile_id" 
                            label="Communication Profile" 
                            onRuxchange={handleTransmissionFormChange} 
                            value={transmissionFormData.comm_profile_id}>
                            <RuxOption value={""} label="No profile" />
                            { profiles.map(profile => (
                                <RuxOption key={profile.profile_id} value={profile.profile_id} label={profile.title} />
                            ))}
                        </RuxSelect>
                        </>
                    ) : (
                        <>
                        <RuxInput
                            label="Booking"
                            name="booking_id"
                            value={transmissionFormData.booking_id}
                            onRuxinput={handleTransmissionFormChange}
                        />
                        <RuxInput
                            style={{marginTop: '15px'}}
                            label="Communication Profile"
                            name="comm_profile_id"
                            value={transmissionFormData.comm_profile_id}
                            onRuxinput={handleTransmissionFormChange}
                        />
                        </>
                    )}
                </div>
                <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <RuxButton id="cancel" secondary="" onClick={closeActivateTransmissionDialog}>
                        Cancel
                    </RuxButton>
                    <RuxButton id="confirm" onClick={handleActivateTransmissionConfirmClick}>
                        Confirm
                    </RuxButton>
                </div>
            </RuxDialog>
        </div>
    );
}

export default TransmissionTable;