import React, { useState, useEffect, useCallback } from 'react';
import { RuxButton, RuxInput, RuxSwitch, RuxTextarea } from '@astrouxds/react';
import * as api from '../../../apis/profileApi';
import { notify } from '../../../libs/notificationSystem/notificationManager';

const ProfileForm = (props) => {
    const [profileForm, setProfileForm] = useState({
        title: '',
        center_rx_frequency: '',
        rx_bandwidth: '',
        polarization: '',
        note: '',
        uplink_enabled: false,
        downlink_enabled: false,
        assigned_object_id: '',
        params: '',
    });

    useEffect(() => {
        const profile = props.selectedProfile || {
            ...profileForm,
            assigned_object_id: props.profileFormData?.assigned_object_id || ''
        };
        setProfileForm(profile);
    }, [props.selectedProfile, props.profileFormData]);

    const handleFormChange = useCallback((e) => {
        const { name, value, checked } = e.target;

        setProfileForm(prevForm => ({
            ...prevForm,
            [name]: checked !== undefined ? checked : value,
        }));

    }, []);

    const handleProfileConfirmClick = useCallback(async () => {
        try {
            const profile = { ...profileForm, assigned_object_id: profileForm.assigned_object_id || null };
            if (props.selectedProfile) {
                profile.profile_id = props.selectedProfile.profile_id;
                await api.updateProfile(profile.profile_id, profile);
            } else {
                await api.createProfile(profile);
            }

            notify('Profile saved', 'normal');
            props.setIsDialogOpen(false);
            props.onProfilesUpdated();
        } catch (error) {
            console.error('Error saving profile:', error);
            notify('Error saving profile.', 'critical');
        }
    }, [profileForm, props]);

    const handleProfileCancelClick = useCallback(() => {
        setProfileForm({
            title: '',
            center_rx_frequency: '',
            rx_bandwidth: '',
            polarization: '',
            note: '',
            uplink_enabled: false,
            downlink_enabled: false,
            assigned_object_id: '',
            params: '',
        });
        props.setIsDialogOpen(false);
    }, [props]);

    return (
        <React.Fragment>
            <span slot="header">{props.selectedProfile?.profile_id ? "Edit profile" : "New profile"}:</span>
            <p>
                <RuxInput
                    label="Title"
                    name="title"
                    onRuxinput={handleFormChange}
                    value={profileForm.title}
                />
            </p>
            <p>
                <RuxInput
                    label="Center RX Frequency"
                    name="center_rx_frequency"
                    onRuxinput={handleFormChange}
                    value={profileForm.center_rx_frequency}
                />
            </p>
            <p>
                <RuxInput
                    label="RX Bandwidth"
                    name="rx_bandwidth"
                    onRuxinput={handleFormChange}
                    value={profileForm.rx_bandwidth}
                />
            </p>
            <p>
                <RuxInput
                    label="Polarization"
                    name="polarization"
                    onRuxinput={handleFormChange}
                    value={profileForm.polarization}
                />
            </p>
            <p>
                <RuxInput
                    label="Note"
                    name="note"
                    onRuxinput={handleFormChange}
                    value={profileForm.note}
                />
            </p>
            <div>
                <div style={{ marginTop: '20px', maxWidth: '5rem' }}>
                    <RuxSwitch
                        label="Uplink Enabled"
                        name="uplink_enabled"
                        checked={profileForm.uplink_enabled}
                        onRuxchange={handleFormChange}
                    ></RuxSwitch>
                </div>
            </div>
            <div>
                <div style={{ marginTop: '20px', maxWidth: '5rem' }}>
                    <RuxSwitch
                        label="Downlink Enabled"
                        name="downlink_enabled"
                        checked={profileForm.downlink_enabled}
                        onRuxchange={handleFormChange}
                    ></RuxSwitch>
                </div>
            </div>
            <p>
                <RuxTextarea
                    label='JSON params'
                    placeholder="JSON params"
                    name="params"
                    onRuxinput={handleFormChange}
                    value={profileForm.params}
                />
            </p>
            <div slot="footer" >
                <RuxButton
                    style={{ paddingRight: '10px' }}
                    id="profile-confirm"
                    onClick={handleProfileConfirmClick}
                >
                    Confirm
                </RuxButton>
                <RuxButton secondary="" onClick={handleProfileCancelClick}>
                    Cancel
                </RuxButton>
            </div>
        </React.Fragment>
    );
};

export default ProfileForm;