import React, { useState, useEffect } from 'react';

import StationTable from "../systems/stationManagement/StationTable";
import StationForm from "../systems/stationManagement/StationForm";
import { RuxAccordionItem } from "@astrouxds/react";
import {useAuth} from "../auth/AuthContext";

const StationManagementScreen = () => {
    const { hasPermission } = useAuth()
    const [selectedStation, setSelectedStation] = useState(null);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    useEffect(() => {
        document.title = "GCN Stations";
    }, []);

    const handleEditStation = (station) => {
        if (!hasPermission('station_manage_*')) {
            return;
        }
        setSelectedStation(station);
        setIsAccordionOpen(true);
    };

    const handleAccordionCollapse = () => {
        setIsAccordionOpen(false); // Collapse the accordion
    };

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '100%',
            justifyContent: 'space-evenly',
            margin: '12px',
            gap: '12px'
        }}>
            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    {hasPermission('station_manage_*') && (<RuxAccordionItem
                        expanded={isAccordionOpen ? true : undefined}
                        onRuxcollapsed={handleAccordionCollapse}
                    >
                        <div slot="label">Add Station</div>
                        <StationForm selectedStation={selectedStation} />
                    </RuxAccordionItem>)}
                    {hasPermission("station_view_all") && <>
                        <br/>
                        <div slot="header">Stations</div>
                        {hasPermission('station_view_*') && (<StationTable handleEditStation={handleEditStation} />)}
                    </>}
                </rux-container>  
            </div>
        </div>
    );
};

export default StationManagementScreen;
