import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {deleteBooking, getBookings} from "../../../apis/bookingApi";
import {notify} from "../../../libs/notificationSystem/notificationManager";
import {Link} from "react-router-dom";
import { useAuth } from "../../../auth/AuthContext";
import CalendarLink from './CalendarLink';
import Pagination from '../../../components/Pagination';

const BookingsTable = () => {
    const [bookings, setBookings] = useState([]);
    const [pageInfo, setPageInfo] = useState({
        page: 0,
        perPage: 20
    })
    const [totalEntries, setTotalEntries] = useState(0)
    const { hasPermission } = useAuth();

    useEffect(() => {
        const loadInitialData = async () => {
            await loadBookings();
        };


        const loadBookings = async () => {
            try {
                const res = await getBookings(undefined, undefined, pageInfo.page*pageInfo.perPage, pageInfo.perPage, true);
                setBookings(res.data || []);
                setTotalEntries(res.entries)
            } catch (error) {
                console.error('Error loading bookings:', error);
            }
        };


        loadInitialData();

        const interval = setInterval(() => {
            loadBookings();
        }, 1000);

        return () => clearInterval(interval);
    }, [pageInfo]);

    const handleDeleteBookingClick = async (bookingId) => {
        try {
            await deleteBooking(bookingId);
            console.log(`Booking with ID ${bookingId} deleted successfully`);
        } catch (error) {
            console.error(`Error deleting booking with ID ${bookingId}: ${error.message}`);
            notify(`Error deleting booking with ID ${bookingId}: ${error.message}`, 'critical');
        }
    };

    const timeToNow = (time) => {
        const now = new Date();
        const futureTime = new Date(time);
        const diffMs = futureTime - now;

        if (diffMs > 0) {
            // Time is in the future
            if (diffMs < 30 * 60 * 1000) { // Less than 30 minutes
                return '-' + moment.utc(diffMs).format('mm:ss'); // Display as countdown
            } else {
                return moment(futureTime).fromNow(); // Display as relative time
            }
        } else {
            // Time is in the past
            return moment(futureTime).fromNow(); // Display as relative time
        }
    };

    const duration = (startTime, endTime) => moment.utc(new Date(endTime) - new Date(startTime)).format('mm:ss');

    const renderStatusIcon = (booking) => {
        const status_map = {
            OK: 'normal',
            WARNING: 'caution',
            STANDBY: 'standby',
            ERR: 'critical',
            null: ''
        };
    
        return status_map[booking.status?.level || null];
    };

    return (
        <div>
        <rux-table>
            <rux-table-body>
                <rux-table-header-row selected="false">
                    <rux-table-header-cell>Detail</rux-table-header-cell>
                    <rux-table-header-cell></rux-table-header-cell>
                    <rux-table-header-cell>Station</rux-table-header-cell>
                    <rux-table-header-cell>Object</rux-table-header-cell>
                    <rux-table-header-cell>AOS</rux-table-header-cell>
                    <rux-table-header-cell>Duration</rux-table-header-cell>
                    {hasPermission('booking_manage_*') && (
                    <rux-table-header-cell>Cancel</rux-table-header-cell>
                    )}
                </rux-table-header-row>
                {bookings
                    .map((b, i) => (
                        <rux-table-row selected="false" key={i}>
                            <rux-table-cell>
                                <Link to={`/bookings/${b.booking_id}`}>
                                    <rux-button secondary icon-only icon="launch" borderless/>
                                </Link>
                            </rux-table-cell>
                            <rux-table-cell>
                            <rux-status status={renderStatusIcon(b)}></rux-status>
                            </rux-table-cell>
                            <rux-table-cell>
                                {b.station.station_name || 'n/a'}
                            </rux-table-cell>
                            <rux-table-cell>{b.object.name  || 'n/a'}
                                <br/>
                                <span style={{
                                    fontSize: '0.8em',
                                    color: "gray"
                                }}>{b.object.norad_id || 'n/a'}</span>
                            </rux-table-cell>
                            <rux-table-cell>
                                {timeToNow(b.time_aos)}
                                <CalendarLink booking={b} />
                                <br/>
                                <span style={{
                                    fontSize: '0.8em',
                                    color: "gray"
                                }}>{moment(b.time_aos).utc().format("HH:mm:ss")}</span>
                            </rux-table-cell>
                            <rux-table-cell>
                                {duration(b.time_aos, b.time_los)}
                                <br/>
                                <span style={{fontSize: '0.8em', color: "gray"}}>{b.maximum
                                    ? "max. [" + parseFloat(b.maximum.azimuth).toFixed(0) + "," + parseFloat(b.maximum.elevation).toFixed(0) + "]"
                                    : 'n/a'}</span>
                            </rux-table-cell>
                            {hasPermission('booking_manage_*') && (
                            <rux-table-cell>
                                <rux-button secondary icon-only icon="remove-circle-outline" borderless
                                            onClick={() => handleDeleteBookingClick(b.booking_id)}/>
                            </rux-table-cell>
                           )}
                        </rux-table-row>
                    ))}

            </rux-table-body>
        </rux-table>
        <div style={{height: '50px'}}>
            <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} totalEntries={totalEntries}/>
        </div>
        </div>

    );
};

export default BookingsTable;
