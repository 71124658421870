import api from './config/axiosConfig';

const TRANSMISSION_API_BASE_URL = 'transmission';

export const getOpenTransmissions = () => {
    return api.get(TRANSMISSION_API_BASE_URL);
};

export const activateTransmission = (data) => {
    return api.post(TRANSMISSION_API_BASE_URL, {
        ...data,
        comm_profile_id: data.comm_profile_id === '' ? null : data.comm_profile_id
    });
};

export const stopTransmission = (transmissionId) => {
    const url = `${TRANSMISSION_API_BASE_URL}/${transmissionId}`;
    return api.delete(url);
};

// export const transmitData = (data) => {
//     const url = `${TRANSMISSION_API_BASE_URL}/${transmissionId}/transmit`;
//     return api.post(url, data)
// }