import React, { useState, useEffect } from 'react';
import {
  RuxButton,
  RuxTable,
  RuxTableHeaderRow,
  RuxTableHeaderCell,
  RuxTableHeader,
  RuxTableRow,
  RuxTableCell,
  RuxCard,
  RuxCheckbox,
  RuxDialog,
  RuxTableBody
} from '@astrouxds/react';
import * as accountApi from '../../apis/accountApi';
import * as objectApi from '../../apis/objectApi';
import { useAuth } from '../../auth/AuthContext';
import { notify } from "../../libs/notificationSystem/notificationManager";

const AccountObjects = ({ selectedAccount }) => {
  const [objects, setObjects] = useState([]);
  const [assignedObjects, setAssignedObjects] = useState([]);
  const [selectedObjectIds, setSelectedObjectIds] = useState([]);
  const [isAssignObjectDialogOpen, setIsAssignObjectDialogOpen] = useState(false);
  const { hasPermission } = useAuth();
  const [allObjects, setAllObjects] = useState([]);

  const loadAllObjects = async () => {
    try {
      const allObjects = await objectApi.getObjects();
      allObjects.sort((a, b) => a.name.localeCompare(b.name));
      setAllObjects(allObjects);
    } catch (error) {
      console.error('Error loading all objects:', error);
    }
  };

  useEffect(() => {
    const loadAssignedObjects = async () => {
      try {
        if (selectedAccount) {
          const response = await accountApi.getAccounts();
          console.log(response)
          const allAccounts = Array.isArray(response) ? response : [];

          const selectedAccountInfo = allAccounts.find(
            (acc) => acc.account_id === selectedAccount.account_id
          );

          if (selectedAccountInfo) {
            const assignedObjectsForSelectedAccount = selectedAccountInfo.objects;
            setAssignedObjects(assignedObjectsForSelectedAccount);

            const unassignedObjects = allObjects.filter(
              (obj) => !assignedObjectsForSelectedAccount.some((assignedObj) => assignedObj.object_id === obj.object_id)
            );
            setObjects(unassignedObjects);
          }
        }
      } catch (error) {
        console.error('Error loading assigned objects:', error);
      }
    };

    if (isAssignObjectDialogOpen || selectedAccount) {
      loadAssignedObjects();
    }
  }, [selectedAccount, isAssignObjectDialogOpen, allObjects]);

  const openAssignObjectDialog = async () => {
    setSelectedObjectIds([]);
    setIsAssignObjectDialogOpen(true);
    await loadAllObjects();
  };

  const closeAssignObjectDialog = () => {
    setIsAssignObjectDialogOpen(false);
  };

  const handleCheckboxChange = (objectId) => {
    setSelectedObjectIds((prevSelectedObjectIds) => {
      if (prevSelectedObjectIds.includes(objectId)) {
        return prevSelectedObjectIds.filter((id) => id !== objectId);
      } else {
        return [...prevSelectedObjectIds, objectId];
      }
    });
  };

  const handleRemoveObject = async (objectId) => {
    try {
      const updatedObjects = assignedObjects.filter((obj) => obj.object_id !== objectId);

      const data = {
        account_id: selectedAccount.account_id,
        account_name: selectedAccount.account_name,
        objects: updatedObjects.map((obj) => ({ object_id: obj.object_id })),
      };

      const response = await accountApi.updateAccount(selectedAccount.account_id, data);
      console.log(response);

      setAssignedObjects(updatedObjects);
      notify(`Object unassigned from account`, 'normal');
    } catch (error) {
      console.error('Error unassigning object from account:', error);
      notify(`Error unassigning object with ID ${objectId} from account.`, 'critical');
    }
  };

  const handleAssignObjects = async () => {
    try {
      if (!selectedAccount) {
        console.error('No account selected');
        return;
      }

      const data = {
        account_id: selectedAccount.account_id,
        account_name: selectedAccount.account_name,
        objects: [
          ...assignedObjects.map(obj => ({ object_id: obj.object_id })),  // Keep existing assigned objects
          ...selectedObjectIds.map(objectId => ({ object_id: objectId })),
        ],
      };

      const response = await accountApi.updateAccount(selectedAccount.account_id, data);
      console.log(response);
      notify('Object assigned to account', 'normal');
      closeAssignObjectDialog();
    } catch (error) {
      console.error('Error assigning object to account:', error);
      notify('Error assigning object to account.', 'critical');
    }
  };

  return (
    <div>
      {hasPermission('object_manage_all') && (
        <RuxCard style={{ width: '100%' }}>

          <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{`Objects for ${selectedAccount ? `${selectedAccount.account_name} Account` : 'Account'}`}</span>
            <RuxButton secondary="" id="assign-object" onClick={openAssignObjectDialog}>
              Assign Object
            </RuxButton>
          </div>

          <RuxTable>
            <RuxTableHeader>
              <RuxTableHeaderRow>
                <RuxTableHeaderCell>Object Name</RuxTableHeaderCell>
                <RuxTableHeaderCell></RuxTableHeaderCell>
              </RuxTableHeaderRow>
            </RuxTableHeader>
            <RuxTableBody>
              {assignedObjects && assignedObjects.length > 0 ? (
                assignedObjects.map((object) => (
                  <RuxTableRow key={object.object_id} style={{ cursor: 'pointer' }}>
                    <RuxTableCell>{object.name}</RuxTableCell>
                    <RuxTableCell style={{ textAlign: 'right' }}>
                      <RuxButton
                        size="small"
                        secondary
                        icon-only
                        icon="remove-circle-outline"
                        borderless
                        onClick={() => handleRemoveObject(object.object_id)}
                      />
                    </RuxTableCell>
                  </RuxTableRow>
                ))
              ) : (
                <RuxTableRow>
                  <RuxTableCell>No objects assigned</RuxTableCell>
                </RuxTableRow>
              )}
            </RuxTableBody>
          </RuxTable>

          <RuxDialog id="assign-object-dialog" open={isAssignObjectDialogOpen} onRuxclosed={closeAssignObjectDialog}>
            <span slot="header">Assign Objects</span>
            <div>
              {objects && objects.length > 0 ? (
                objects.map((object) => (
                  <div key={object.object_id} style={{ display: 'flex', alignItems: 'center' }}>
                    <RuxCheckbox
                      style={{ marginRight: '10px' }}
                      name={`object-checkbox-${object.object_id}`}
                      label={object.name}
                      onRuxchange={() => handleCheckboxChange(object.object_id)}
                      checked={selectedObjectIds.includes(object.object_id)}
                    />
                  </div>
                ))
              ) : (
                <div>No objects available to assign</div>
              )}
            </div>
            <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <RuxButton id="cancel" secondary="" onClick={closeAssignObjectDialog}>
                Cancel
              </RuxButton>
              {objects && objects.length > 0 ? (
                <RuxButton id="confirm" onClick={handleAssignObjects}>
                  Confirm
                </RuxButton>
              ) : ''
              }
            </div>
          </RuxDialog>
        </RuxCard>
      )}
    </div>
  );
};

export default AccountObjects;