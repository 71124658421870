import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

let logoutFunc;

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate();

    const updatePermissions = useCallback((userData) => {
        let permNames = (userData.permissions || []).map(perm => perm.name);
        setPermissions(permNames);
    }, []);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedToken = localStorage.getItem('token');

        if (storedUser && storedToken) {
            try {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
                setIsAuthenticated(true);
                updatePermissions(parsedUser);
            } catch (error) {
                console.error('Error parsing user from localStorage', error);
            }
        }
    }, [updatePermissions]);

    const login = useCallback((userData, token) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        updatePermissions(userData);
        navigate('/schedule');
    }, [updatePermissions, navigate]);

    const logout = useCallback(() => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setPermissions([]);
        navigate('/');
    }, [navigate]);

    // Assign the logout function to logoutFunc
    logoutFunc = logout;

    const hasPermission = useCallback((...requestedPermissions) => {
        return requestedPermissions.some(permission => {
            if (permission.endsWith('*')) {
                const prefix = permission.slice(0, -1);
                return permissions.some(p => p.startsWith(prefix));
            } else {
                return permissions.includes(permission);
            }
        });
    }, [permissions]);

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout, hasPermission }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

// Export the logout function
export const getLogoutFunction = () => logoutFunc;
