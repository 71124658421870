import axios from 'axios';
import {notify} from "../../libs/notificationSystem/notificationManager";
import {getLogoutFunction} from "../../auth/AuthContext";

// Create an axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' },
});

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
    // Check if 'skipAuthHeader' is true, then skip adding Authorization header
    if (!config.skipAuthHeader) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, (error) => Promise.reject(error));

// Response success handler
const handleSuccess = (response) => {
    if(response.headers['x-total-entries']) {
        return {
            data: response.data,
            entries: response.headers['x-total-entries']
        }
    }
    return response.data
};

// Response failure handler
const handleFailure = (error) => {
    let message = 'An error occurred';
    if (error.response) {
        if (error.response.status === 401) {
            message = 'Insufficient permission';
            const logout = getLogoutFunction();
            if (logout) logout();
        } else {
            console.log(error.response);
            message = `Error: ${error.response.status} ${error.response.data.message}`;
        }
    } else if (error.request) {
        message = 'Error: No response received from backend';
    } else {
        message = `Error: ${error.message}`;
    }

    notify(message, 'critical', null, 1);
    return Promise.reject(error);
};

// Generalized request function
const makeRequest = async (config) => {
    try {
        const response = await axiosInstance(config);
        return handleSuccess(response);
    } catch (error) {
        return handleFailure(error);
    }
};

const api = {
    get: (url, config = {}) => makeRequest({ method: 'get', url, ...config }),
    post: (url, data = {}, config = {}) => makeRequest({ method: 'post', url, data, ...config }),
    put: (url, data = {}, config = {}) => makeRequest({ method: 'put', url, data, ...config }),
    patch: (url, data = {}, config = {}) => makeRequest({ method: 'patch', url, data, ...config }),
    delete: (url, config = {}) => makeRequest({ method: 'delete', url, ...config }),
};

export default api;
