import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RuxTable, RuxTableHeader, RuxTableHeaderRow, RuxTableBody, RuxTableRow, RuxTableCell, RuxInput, RuxButton } from '@astrouxds/react';
import { getBooking } from "../../../apis/bookingApi";
import CalendarLink from './CalendarLink';

function BookingDetail({ booking: initialBooking }) {
    const [booking, setBooking] = useState(initialBooking || null);
    const [newBooking, setNewBooking] = useState('');

    useEffect(() => {
        if (initialBooking && initialBooking !== booking) {
            setBooking(initialBooking);
        }
    }, [initialBooking, booking]);

    const handleInputChange = (event) => {
        setNewBooking(event.target.value);
    };

    const handleLoadBooking = async () => {
        try {
            const bookingData = await getBooking(newBooking);
            setBooking(bookingData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const renderStatusIcon = (booking) => {
        const status_map = {
            OK: 'normal',
            WARNING: 'caution',
            STANDBY: 'standby',
            ERR: 'critical',
            null: ''
        };

        return status_map[booking.status?.level || null];
    };

    const renderCommandSynchronizedIcon = () => {
        const response = {
            true: 'normal',
            false: 'critical',
        };
        return response[booking.sequence_sync_successful];
    };

    const renderStatusText = () => {
        const response = {
            true: "Sync OK",
            false: "N/A"
        };
        return response[booking.sequence_sync_successful];
    };

    const renderBookingTable = () => {
        if (!booking) {
            return null;
        }

        const bookingInfo = [
            { label: 'Object', value: booking.object.name || 'N/A' },
            { label: 'Station', value: booking.station.station_name || 'N/A' },
            { label: 'Status', value:
                    <div style={{ display: 'flex' }}>
                        <rux-status style={{ marginRight: '3px' }} status={renderStatusIcon(booking) || 'N/A'}></rux-status>
                        {booking.status?.status_id || 'N/A'}
                    </div>
            },
            { label: 'Last Command Synchronized', value:
                    <div style={{ display: 'flex' }}>
                        <rux-status style={{ marginRight: '3px' }} status={renderCommandSynchronizedIcon(booking) || 'N/A'}></rux-status>
                        {renderStatusText(booking) || 'N/A'}
                    </div>
            },
            { label: 'Date', value: 
                    <div>
                        {moment(booking.time_start).isValid() ? moment(booking.time_start).utc().format("YYYY-MM-DD") : 'N/A'}
                        <CalendarLink booking={booking} />
                    </div>
            },
            { label: 'DOY', value: moment(booking.time_start).isValid() ? moment(booking.time_start).utc().dayOfYear() : 'N/A' },
            { label: 'Start Time', value: moment(booking.time_start).isValid() ? moment(booking.time_start).utc().format("HH:mm:ss") : 'N/A' },
            { label: 'AOS Time', value: moment(booking.time_aos).isValid() ? moment(booking.time_aos).utc().format("HH:mm:ss") : 'N/A', },
            { label: 'LOS Time', value: moment(booking.time_los).isValid() ? moment(booking.time_los).utc().format("HH:mm:ss") : 'N/A' },
            { label: 'End Time', value: moment(booking.time_end).isValid() ? moment(booking.time_end).utc().format("HH:mm:ss") : 'N/A' },
            { label: 'Max Elevation, Azimuth', value: booking.maximum ? parseFloat(booking.maximum.elevation).toFixed(2) + "°, " + parseFloat(booking.maximum.azimuth).toFixed(2) + "°" : 'N/A' }]
        
        return (
            <div>
                <RuxTable>
                    <RuxTableHeader>
                        <RuxTableHeaderRow>
                            <td colSpan={2}> {booking.booking_id} </td>
                        </RuxTableHeaderRow>
                    </RuxTableHeader>

                    <RuxTableBody>
                        {bookingInfo.map(item => (
                            <RuxTableRow key={item.label}>
                                <RuxTableCell>{item.label}</RuxTableCell>
                                <RuxTableCell>{item.value}</RuxTableCell>
                            </RuxTableRow>))}
                    </RuxTableBody>
                </RuxTable>
            </div>
        );
    };

    return (
        <div>
            {!initialBooking &&
                <div style={{ display: 'flex', gap: '10px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end', width: '100%', paddingBottom: '20px' }}>
                    <RuxInput type="text" style={{ width: '80%' }} label="Booking ID" value={newBooking} onRuxchange={handleInputChange} />
                    <RuxButton onClick={handleLoadBooking}>Display</RuxButton>
                </div>
            }
            {renderBookingTable()}
        </div>
    );
}

export default BookingDetail;