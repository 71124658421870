import React from 'react';
import ReceptionTable from '../systems/communicationManagement/ReceptionTable';
import TransmissionTable from '../systems/communicationManagement/TransmissionTable';
import { useAuth } from "../auth/AuthContext";

const SandboxScreen = () => {
    const { hasPermission } = useAuth();

    return (
        <div>
            {hasPermission('reception_manage_all') && (
                    <ReceptionTable label='Open Receptions' />
            )}
            {hasPermission('transmission_manage_all') && (
                    <TransmissionTable label='Open Transmissions' />
            )}
        </div>
    )
}

export default SandboxScreen