import React, { useState, useCallback, useContext } from 'react';
import { RuxNotification } from '@astrouxds/react';
import { setNotificationHandler } from './notificationManager';

export const NotificationContext = React.createContext(null);

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const removeNotification = useCallback((id) => {
        setNotifications(notifs => notifs.filter(notif => notif.id !== id));
    }, []);

    const addNotification = useCallback((message, status = 'standby', closeAfter = 4000, id = null) => {
        const notificationId = id || Date.now();
        if (id) removeNotification(id);
        setNotifications(notifs => [...notifs, { id: notificationId, message, status, closeAfter }]);
    }, [removeNotification]);

    setNotificationHandler(addNotification, removeNotification);

    return (
        <NotificationContext.Provider value={{ addNotification, removeNotification }}>
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '7rem',
                    right: '1rem',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    zIndex: '1000'
                }}
            >
                {notifications.map(({ id, message, status, closeAfter }) => (
                    <div key={id} style={{ marginBottom: '5px', width: '400px' }}>
                        <RuxNotification
                            open
                            small
                            status={status}
                            onRuxclosed={() => removeNotification(id)}
                            close-after={closeAfter}
                        >
                            <div style={{ whiteSpace: 'normal' }}>
                                {message}
                            </div>
                        </RuxNotification>
                    </div>
                ))}
            </div>
            {children}
        </NotificationContext.Provider>
    );
};