import React, { useState, useEffect } from 'react';
import {
    RuxButton,
    RuxTable,
    RuxTableBody,
    RuxTableHeaderRow,
    RuxTableHeaderCell,
    RuxTableRow,
    RuxTableCell,
    RuxTableHeader
} from '@astrouxds/react';
import { useAuth } from "../../../auth/AuthContext";
import { notify } from "../../../libs/notificationSystem/notificationManager";
import { useNavigate } from 'react-router-dom';
import * as api from '../../../apis/commandApi'

const TemplateTable = ({ handleEditTemplate, selectedTemplate }) => {
    const [templateList, setTemplateList] = useState([]);
    const { hasPermission } = useAuth();
    const navigate = useNavigate()

    useEffect(() => {
        const loadTemplates = async () => {
            try {
                const templates = await api.getCommandTemplates() || [];
                templates.sort((a, b) => a.name.localeCompare(b.name));
                setTemplateList(templates);
            } catch (error) {
                console.error('Error loading templates:', error);
            }
        };

        loadTemplates();
        const interval = setInterval(loadTemplates, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleEditClick = (template) => {
        console.log('Editing template:', template);
        if (handleEditTemplate) {
            handleEditTemplate(template);
        }
    };

    const handleRemoveClick = async (templateId) => {
        try {
            await api.deleteCommandTemplate(templateId);
            setTemplateList((prevTemplates) =>
                prevTemplates.filter((template) => template.template_id !== templateId)
            );
            if (selectedTemplate?.template_id === templateId) {
                handleEditTemplate(null);
            }
            notify('Template removed successfully', 'normal');
        } catch (error) {
            console.error('Error removing template:', error);
            notify('Error removing template', 'error');
        }
    };
    return (
        <div>

            <RuxTable >
                <RuxTableHeader>
                    <RuxTableHeaderRow selected={false}>
                        <RuxTableHeaderCell>Template Name</RuxTableHeaderCell>
                            <RuxTableHeaderCell></RuxTableHeaderCell>
                    </RuxTableHeaderRow>
                </RuxTableHeader>
                <RuxTableBody>
                    {templateList.map((template, index) => (
                        <RuxTableRow key={index}>
                            <RuxTableCell>{template.name}</RuxTableCell>

                            <RuxTableCell style={{ textAlign: 'right' }}>
                                <RuxButton
                                    size="small"
                                    secondary
                                    icon-only
                                    icon="create"
                                    borderless
                                    onClick={() => handleEditClick(template)}
                                />
                                {hasPermission('template_manage_all') && (
                                <RuxButton size="small" secondary icon-only icon="remove-circle-outline"
                                    borderless onClick={() => handleRemoveClick(template.template_id)}
                                />
                                )}
                            </RuxTableCell>

                        </RuxTableRow>
                    ))}
                </RuxTableBody>
            </RuxTable>
        </div>
    );
};

export default TemplateTable;