import React, { useEffect, useState } from 'react';
import CombinedPassesTable from '../systems/schedule/components/CombinedPassesTable.js'
import TimelineComponent from "../systems/schedule/components/TimelineComponent";
import { getBookings, deleteBooking } from "../apis/bookingApi";
import { getObjects } from "../apis/objectApi";
import { getStations } from "../apis/stationApi";
import { useAuth } from "../auth/AuthContext.js";
import moment from 'moment';
import { getPredictions } from "../apis/predictionAPi.js";
import { notify } from "../libs/notificationSystem/notificationManager.js";
import FuturePassesTable from '../systems/schedule/components/FuturePassesTable.js';
import UpcomingBookings from '../systems/schedule/components/UpcomingBookingsTable.js';

const ScheduleScreen = () => {
    const [objects, setObjects] = useState([]);
    const [stations, setStations] = useState([]);
    const [bookings, setBookings] = useState([]);
    const { hasPermission } = useAuth();
    const [upcomingPredictions, setUpcomingPredictions] = useState([]);
    const [updatedPredictions, setUpdatedPredictions] = useState([]);
    const [shouldPredictionRefresh, setShouldPredictionRefresh] = useState(false);
    const [showConfirmed, setShowConfirmed] = useState(true);
    const [showRequested, setShowRequested] = useState(true);
    const [showRejected, setShowRejected] = useState(true);
    const [showPredictions, setShowPredictions] = useState(false);
    const [zoom, setZoom] = useState(12);

    useEffect(() => {
        document.title = "GCN Schedule";
        const loadInitialData = async () => {
            await loadObjects();
            await loadStations();
            await loadBookings();
        };

        const loadObjects = async () => {
            try {
                const res = await getObjects();
                res.sort((a, b) => a.name.localeCompare(b.name));
                setObjects(res || []);
            } catch (error) {
                console.error('Error loading objects:', error);
            }
        };

        const loadStations = async () => {
            try {
                const res = await getStations();
                res.sort((a, b) => a.station_name.localeCompare(b.station_name));
                setStations(res || []);
            } catch (error) {
                console.error('Error loading stations:', error);
            }
        };

        const loadBookings = async () => {
            const timeNow = new Date();
            timeNow.setHours(timeNow.getHours() - 3);
            const timeFrom = timeNow.toISOString();
            try {
                const res = await getBookings(timeFrom);
                setBookings(res || []);
            } catch (error) {
                console.error('Error loading bookings:', error);
            }
        };
        loadInitialData();
        const interval = setInterval(() => {
            loadBookings();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // useEffect(() => {
    //     handleTimelineFormSubmit();
    // }, []);

    const handleTimelineFormSubmit = async () => {
        const nowUTC = moment.utc();
        const time_from = nowUTC;
        const time_to = nowUTC.clone().add(24, 'hours');

        try {
            const [objectsResponse, stationsResponse] = await Promise.all([
                getObjects(),
                getStations()
            ]);

            const allObjectIds = objectsResponse.map(object => String(object.norad_id));
            const selectedObjects = allObjectIds.map(norad_id => ({ norad_id: parseInt(norad_id, 10) }));

            const allStationIds = stationsResponse.map(station => station.station_id);
            const selectedStations = allStationIds.map(station_id => ({ station_id: station_id }));

            const fetchedPredictions = await getPredictions(selectedObjects, selectedStations, time_from.toISOString(), time_to.toISOString());
            console.log(fetchedPredictions);
            fetchedPredictions?.sort((a, b) => moment(a.time_aos).diff(moment(b.time_aos)));
            setUpcomingPredictions(fetchedPredictions);

        } catch (error) {
            console.error('Error calculating predictions:', error);
            notify('Error calculating predictions: ' + error.message, 'critical');
        }
    };

    const handleDeleteBookingClick = async (bookingId) => {
        try {
            await deleteBooking(bookingId);
            if (shouldPredictionRefresh) {
                handleTimelineFormSubmit();
                setShouldPredictionRefresh(false);
            }
            console.log(`Booking with ID ${bookingId} deleted successfully`);
        } catch (error) {
            console.error(`Error deleting booking with ID ${bookingId}: ${error.message}`);
            notify(`Error deleting booking with ID ${bookingId}: ${error.message}`, 'critical');
        }
    };

    useEffect(() => {
        if (updatedPredictions.length > 0) {
            updateUpcomingPredictions(updatedPredictions);
        }
    }, [updatedPredictions]);

    const updateUpcomingPredictions = () => {
        const updatedPredictionKeys = new Set(
            updatedPredictions.map(pred => `${pred.object.object_id}-${pred.station.station_id}`)
        );
        const filteredUpcomingPredictions = upcomingPredictions.filter(
            pred => !updatedPredictionKeys.has(`${pred.object.object_id}-${pred.station.station_id}`)
        );
        const combinedPredictions = [...filteredUpcomingPredictions, ...updatedPredictions];
        setUpcomingPredictions(combinedPredictions);
    };

    return (
        <div>
            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    <div slot="header">Timeline</div>
                    <rux-tabs style={{ marginBottom: '20px' }} id="tab-set-1" small="">
                        <rux-tab id="tab-1">Stations - Objects</rux-tab>
                        <rux-tab id="tab-2">Objects - Stations</rux-tab>
                    </rux-tabs>
                    <rux-tab-panels aria-labelledby="tab-set-1">
                        <rux-tab-panel aria-labelledby="tab-1">
                            <TimelineComponent
                                tracks={stations}
                                subtracks={objects}
                                regions={bookings}
                                bookings={bookings}
                                zoom={zoom} 
                                setZoom={setZoom}
                                handleTimelineFormSubmit={handleTimelineFormSubmit}
                                upcomingPredictions={upcomingPredictions}
                                showConfirmed={showConfirmed}
                                setShowConfirmed={setShowConfirmed}
                                showRequested={showRequested}
                                setShowRequested={setShowRequested}
                                showRejected={showRejected}
                                setShowRejected={setShowRejected}
                                showPredictions={showPredictions}
                                setShowPredictions={setShowPredictions} />
                        </rux-tab-panel>
                        <rux-tab-panel aria-labelledby="tab-2">
                        <TimelineComponent
                                tracks={objects}
                                subtracks={stations}
                                regions={bookings}
                                bookings={bookings}
                                zoom={zoom} 
                                setZoom={setZoom}
                                handleTimelineFormSubmit={handleTimelineFormSubmit}
                                upcomingPredictions={upcomingPredictions}
                                showConfirmed={showConfirmed}
                                setShowConfirmed={setShowConfirmed}
                                showRequested={showRequested}
                                setShowRequested={setShowRequested}
                                showRejected={showRejected}
                                setShowRejected={setShowRejected}
                                showPredictions={showPredictions}
                                setShowPredictions={setShowPredictions} />
                        </rux-tab-panel>
                    </rux-tab-panels>
                </rux-container>
            </div>
            {/* <div style={{
                display: 'grid',
                gridTemplateColumns: '100%',
                justifyContent: 'space-evenly',
                margin: '12px',
                gap: '12px'
            }}>

                {hasPermission('booking_manage_*') && (
                    <div style={{ gridColumn: '1' }}>
                        <rux-container style={{ margin: '12px 0' }}>
                            <div slot="header">Upcoming observations and passes</div>
                            <CombinedPassesTable
                                handleTimelineFormSubmit={handleTimelineFormSubmit}
                                setShouldPredictionRefresh={setShouldPredictionRefresh}
                                handleDeleteBookingClick={handleDeleteBookingClick}
                                setUpdatedPredictions={setUpdatedPredictions}
                            />
                        </rux-container>
                    </div>
                )}
            </div> */}
            <div style={{
                display: 'grid',
                gridTemplateColumns: '100%',
                justifyContent: 'space-evenly',
                margin: '12px',
                gap: '12px'
            }}>

                {hasPermission('booking_manage_*') && (
                    <div style={{ gridColumn: '1' }}>
                        <rux-container style={{ margin: '12px 0' }}>
                            <div slot="header">Prediction calculator</div>
                            <FuturePassesTable
                                handleTimelineFormSubmit={handleTimelineFormSubmit}
                                setShouldPredictionRefresh={setShouldPredictionRefresh}
                                setUpdatedPredictions={setUpdatedPredictions}
                            />
                        </rux-container>
                    </div>
                )}
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '100%',
                justifyContent: 'space-evenly',
                margin: '12px',
                gap: '12px'
            }}>

                {hasPermission('booking_manage_*') && (
                    <div style={{ gridColumn: '1' }}>
                        <rux-container style={{ margin: '12px 0' }}>
                            <div slot="header">Upcoming bookings</div>
                            <UpcomingBookings
                                handleDeleteBookingClick={handleDeleteBookingClick}
                            />
                        </rux-container>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ScheduleScreen;