import api from './config/axiosConfig';

const ROLE_API_BASE_URL = 'roles';

export const createRole = (data) => {
    return api.post(ROLE_API_BASE_URL, data);
};

export const getRole = () => {
    return api.get(ROLE_API_BASE_URL);
};

export const updateRole = (roleId, data) => {
    const url = `${ROLE_API_BASE_URL}/${roleId}`;
    return api.put(url, data);
};

export const deleteRole = (roleId) => {
    const url = `${ROLE_API_BASE_URL}/${roleId}`;
    return api.delete(url);
};