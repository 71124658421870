import React from "react";
import "./PassPlan.css";
import UniversalComponent from "./UniversalComponent";

const PassPlan = ({ booking }) => {

    const loadTemplateCommandsCaller = async () => {
        try {
            console.log('loadTemplateCommandsCaller function invoked!');

        } catch (error) {
            console.error('Error in loadTemplateCommandsCaller:', error);
        }
    };

    const saveBookingCommandsCaller = async () => {
        try {
            console.log('saveBookingCommandsCaller function invoked!');

        } catch (error) {
            console.error('Error in saveBookingCommandsCaller:', error);
        }
    };

    const executeButtonClickCaller = async () => {
        try {
            console.log('executeButtonClickCaller function invoked!');

        } catch (error) {
            console.error('Error in executeButtonClickCaller:', error);
        }
    };

    const displayStatus = async () => {
        try {
            console.log('displayStatus function invoked!');

        } catch (error) {
            console.error('Error in displayStatus:', error);
        }
    };
    
    const displayRelative = async () => {
        try {
            console.log('displayRelative function invoked!');

        } catch (error) {
            console.error('Error in displayRelative:', error);
        }
    };

    const runAllCommandsAtTimesCaller = async () => {
        try {
            console.log('runAllCommandsAtTimesCaller function invoked!');

        } catch (error) {
            console.error('Error in runAllCommandsAtTimesCaller:', error);
        }
    };

    return (
        <div>
            <UniversalComponent
                booking={booking}
                loadTemplateCommandsCaller={loadTemplateCommandsCaller}
                saveBookingCommandsCaller={saveBookingCommandsCaller}
                executeButtonClickCaller={executeButtonClickCaller}
                displayStatus={displayStatus}
                displayRelative={displayRelative}
                runAllCommandsAtTimesCaller={runAllCommandsAtTimesCaller}
                label="Pass Plan" />
        </div>
    );
};

export default PassPlan;