import React, { useState, useEffect } from 'react';
import {
    RuxButton,
    RuxTable,
    RuxTableBody,
    RuxTableHeaderRow,
    RuxTableHeaderCell,
    RuxTableRow,
    RuxTableCell
} from '@astrouxds/react';
import * as api from "../../apis/stationApi";
import { useAuth } from "../../auth/AuthContext";
import { notify } from "../../libs/notificationSystem/notificationManager";
import { Link } from "react-router-dom";

const StationTable = (props) => {
    const [stations, setStations] = useState([]);
    const { hasPermission } = useAuth()


    useEffect(() => {
        const loadStations = async () => {
            try {
                const stations = await api.getStations() || [];
                stations.sort((a, b) => a.station_name.localeCompare(b.station_name));
                setStations(stations);
            } catch (error) {
                console.error('Error loading stations:', error);
            }
        };

        loadStations();
        const interval = setInterval(loadStations, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleEditStationClick = (station_id) => {
        if (!hasPermission('station_manage_all')) return;
        const selectedStation = stations.find((stat) => stat.station_id === station_id);
        props.handleEditStation(selectedStation);
    };

    const handleDeleteStationClick = async (station_id) => {
        if (!hasPermission('station_manage_all')) return;
        try {
            await api.deleteStation(station_id);
            console.log(`Station with ID ${station_id} deleted successfully`);
            notify(`Station deleted successfully`, 'normal');
            setStations(stations.filter(station => station.station_id !== station_id));
        } catch (error) {
            console.error(`Error deleting station with ID ${station_id}: ${error.message}`);
            notify(`Error deleting station: ${error.message}`, 'critical');
        }
    };

    const renderStatusIcon = (station) => {
        return station.connected ? 'normal' : 'off';
    };

    return (
        <div>
            <RuxTable>
                <RuxTableBody>
                    <RuxTableHeaderRow selected={false}>
                        <RuxTableHeaderCell>Detail</RuxTableHeaderCell>
                        <rux-table-header-cell></rux-table-header-cell>
                        <RuxTableHeaderCell>Station Name</RuxTableHeaderCell>
                        <RuxTableHeaderCell>Port ID</RuxTableHeaderCell>
                        {hasPermission('station_manage_all') && (
                            <RuxTableHeaderCell></RuxTableHeaderCell>
                        )}
                    </RuxTableHeaderRow>
                    {stations
                        .map((station, i) => {

                            return (
                                <RuxTableRow key={i}>
                                    <rux-table-cell>
                                        <Link to={`/stations/${station.station_id}`}>
                                            <rux-button secondary icon-only icon="launch" borderless />
                                        </Link>
                                    </rux-table-cell>
                                    <rux-table-cell>
                                    <rux-status status={renderStatusIcon(station)}></rux-status>
                                    </rux-table-cell>
                                    <RuxTableCell>{station.station_name}</RuxTableCell>
                                    <RuxTableCell>{station.port_assignment_id}</RuxTableCell>

                                    {hasPermission('station_manage_all') && (
                                        <RuxTableCell style={{ textAlign: 'right' }}>
                                            <>
                                                <RuxButton size="small" secondary icon-only icon="create" borderless
                                                    onClick={() => handleEditStationClick(station.station_id)} />
                                                <RuxButton size="small" secondary icon-only icon="remove-circle-outline"
                                                    borderless
                                                    onClick={() => handleDeleteStationClick(station.station_id)} />
                                            </>
                                        </RuxTableCell>
                                    )}
                                </RuxTableRow>
                            )
                        })
                    }
                </RuxTableBody>
            </RuxTable>
        </div>
    );
};

export default StationTable;
