import React, { Component } from 'react';
import { RuxInput } from '@astrouxds/react';
import * as api from "../../apis/stationApi";
import {notify} from "../../libs/notificationSystem/notificationManager";

class StationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                station_id: '',
                station_name: '',
                address: '',
                mqtt_address: '',
                altitude: '',
                latitude: '',
                longitude: '',
                port_assignment_id: ''
            },
            selectedStation: '',
        };
        this.interval = null;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedStation !== prevProps.selectedStation) {
            const { station_id, station_name, address, mqtt_address, location, port_assignment_id } = this.props.selectedStation;

    
            if (location && typeof location === 'object') {
                const { altitude, latitude, longitude } = location;
    
                this.setState((prevState) => ({
                    form: {
                        ...prevState.form,
                        station_id,
                        station_name,
                        address,
                        mqtt_address,
                        altitude,
                        latitude,
                        longitude,
                        port_assignment_id
                    },
                    selectedStation: this.props.selectedStation,
                }));
            } else {
                // Handle the case where location is null or not an object
                console.error('Invalid location:', location);
            }

        }
    }


    handleFormChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: value,
            },
        }));
    };

    handleSaveClick = async () => {
        try {
            // Extract data from the form
            const { station_id,  station_name, address, mqtt_address, altitude, latitude, longitude, port_assignment_id } = this.state.form;

            // Create or update the station using the API functions
            const station = {
                station_id: station_id || null,
                station_name: station_name || null,
                address: address || null,
                mqtt_address: mqtt_address || null,

                location: {
                    altitude: altitude || null,
                    latitude: latitude || null,
                    longitude: longitude || null,
                },
                port_assignment_id: Number(port_assignment_id) || null
            };

            let response = null;
            console.log(station_id);
            if(station_id) {
                response = await api.updateStation(station_id, station);
            } else {
                response = await api.createStation(station);
            }
            console.log(response)
            notify('Station saved', 'normal');


            // Clear the form by resetting the form state
            this.setState({
                form: {
                    station_id: '',
                    station_name: '',
                    address: '',
                    mqtt_address: '',
                    altitude: '',
                    latitude: '',
                    longitude: '',
                    port_assignment_id: ''
                },
                 selectedStation: '',
            });
        } catch (error) {
            console.error('Error saving station:', error);
            notify('Error saving station.', 'critical');
            // Handle the error, such as displaying an error message to the user
        }
    };

    handleCancelClick() {
        this.setState({
            form: {
                    station_id: '',
                    station_name: '',
                    mqtt_address: '',
                    address: '',
                    altitude: '',
                    latitude: '',
                    longitude: '',
                    port_assignment_id: ''
                },
                 selectedStation: '',
        });
    }

    render() {
        return (
            <div>
                <p>

                    Station Name: <RuxInput name="station_name" onRuxinput={this.handleFormChange} value={this.state.form.station_name} />
                </p>
                <p>
                    Address: <RuxInput name="address" onRuxinput={this.handleFormChange} value={this.state.form.address} />
                </p>
                <p>
                    MQTT Address: <RuxInput name="mqtt_address" onRuxinput={this.handleFormChange} value={this.state.form.mqtt_address} />
                </p>
                <p>
                    Altitude: <RuxInput name="altitude" onRuxinput={this.handleFormChange} value={this.state.form.altitude} />
                </p>
                <p>
                    Latitude: <RuxInput name="latitude" onRuxinput={this.handleFormChange} value={this.state.form.latitude} />
                </p>
                <p>
                    Longitude: <RuxInput name="longitude" onRuxinput={this.handleFormChange} value={this.state.form.longitude} />
                </p>
                <p>
                    Port ID: <RuxInput name="port_assignment_id" onRuxinput={this.handleFormChange} value={this.state.form.port_assignment_id} />
                </p>
                <p>
                    <rux-button-group h-align="left">
                        <rux-button onClick={() => this.handleSaveClick()}>Save Station</rux-button>
                        <rux-button secondary="" onClick={() => this.handleCancelClick()}>Cancel</rux-button>
                    </rux-button-group>
                </p>
            </div>
        );
    }
}

export default StationForm;
