import React, { useEffect, useState } from 'react';
import BookingDetail from "../systems/schedule/components/BookingDetail";
import { getBooking } from "../apis/bookingApi";
import { useParams } from "react-router-dom";
import PassPlan from "../systems/commands/passplan/PassPlan";
import ReceptionTable from '../systems/communicationManagement/ReceptionTable';
import TransmissionTable from '../systems/communicationManagement/TransmissionTable';
import { useAuth } from "../auth/AuthContext";

function BookingDetailScreen() {
    const [booking, setBooking] = useState(null);
    const { id } = useParams();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const fetchBooking = async () => {
            try {
                const bookingData = await getBooking(id);
                setBooking(bookingData);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchBooking();
    }, [id]);

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: '33% 66%', justifyContent: 'space-evenly', padding: '10px 0' }}>
                <div style={{ gridColumn: 1 }}>
                    <rux-card>
                        <div slot="header">Booking Detail</div>
                        <BookingDetail booking={booking} />
                    </rux-card>
                    {hasPermission('reception_manage_all') && (
                            <ReceptionTable booking={booking} label='Open Receptions' />
                    )}
                    {hasPermission('transmission_manage_all') && (
                            <TransmissionTable booking={booking} label='Open Transmissions' />
                    )}
                </div>
                <div style={{ gridColumn: 2 }}>
                    <PassPlan booking={booking} />
                </div>
            </div>
        </div>
    );
}

export default BookingDetailScreen;