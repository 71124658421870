import React, { useState, useEffect } from 'react';
import {
  RuxButton,
  RuxTable,
  RuxTableBody,
  RuxTableHeader,
  RuxTableHeaderRow,
  RuxTableHeaderCell,
  RuxTableRow,
  RuxTableCell,
  RuxDialog,
  RuxInput,
  RuxSelect,
  RuxOption,
  RuxCard,
} from '@astrouxds/react';
import * as api from '../../apis/userApi';
import * as roleApi from '../../apis/roleApi';
import { useAuth } from "../../auth/AuthContext";
import {notify} from "../../libs/notificationSystem/notificationManager";

function UserTable({ selectedAccount }) {
  const [users, setUsers] = useState([]);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [isUserConfirmationDialogOpen, setIsUserConfirmationDialogOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { hasPermission } = useAuth();
  const [newUserForm, setNewUserForm] = useState({
    username: '',
    password: '',
    role_name: '',
  });
  const [editUserForm, setEditUserForm] = useState({
    userId: null,
    username: '',
    password: '',
    role_name: '',
  });

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const users = await api.getUsersByAccountId(selectedAccount?.account_id) || [];
        users.sort((a, b) => a.username.localeCompare(b.username));
        setUsers(users);
      } catch (error) {
        console.error('Error loading users:', error);
      }
    };

    loadUsers();
  }, [selectedAccount]);

  useEffect(() => {
    const fetchRoles = async () => {
      if (!hasPermission('role_view_*')) return;
      try {
        const rolesData = await roleApi.getRole();
        setRoles(rolesData || []);
      } catch (error) {
        console.error('Error loading roles:', error);
        setRoles([]);
      }
    };
    fetchRoles();
  }, []);

  const openAddUserDialog = () => {
    if (!hasPermission('user_manage_*')) return;
    setIsAddUserDialogOpen(true);
    setNewUserForm({
      username: '',
      password: '',
      role_name: '',
    });
  };

  const closeAddUserDialog = () => {
    setIsAddUserDialogOpen(false);
    setNewUserForm({
      username: '',
      password: '',
      role_name: '',
    });
  };

  const openEditUserDialog = (user) => {
    if (!hasPermission('user_manage_*')) return;
    setEditUserForm({
      userId: user.user_id,
      username: user.username,
      password: '',
      role_name: user.role_name,
    });

    setIsEditUserDialogOpen(true);
  };

  const closeEditUserDialog = () => {
    setIsEditUserDialogOpen(false);
    // Reset the form values
    setEditUserForm({
      userId: null,
      username: '',
      password: '',
      role_name: '',
    });
  };

  const handleNewUserFormChange = (e) => {
    const { name, value } = e.target;
    setNewUserForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleEditUserFormChange = (e) => {
    const { name, value } = e.target;
    setEditUserForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleAddUserClick = async () => {
    try {
      const newUserData = {
        account_id: selectedAccount?.account_id,
        username: newUserForm.username,
        password: newUserForm.password,
        role: newUserForm.role_name,
      };
      const response = await api.createUser(newUserData);
      console.log(response);
      notify('User created', 'normal');
      setIsAddUserDialogOpen(false);

      const updatedUsers = await api.getUsersByAccountId(selectedAccount?.account_id);
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error creating user:', error);
      notify('Error creating user.', 'critical');
    }
  };

  const handleEditUserClick = async () => {
    try {
      const { userId, username, password, role_name } = editUserForm;
      const response = await api.editUser(userId, {
        username,
        password,
        role: role_name,
      });
      console.log(response);
      notify('User updated', 'normal');

      const updatedUsers = await api.getUsersByAccountId(selectedAccount?.account_id);
      setUsers(updatedUsers);

      closeEditUserDialog();
    } catch (error) {
      console.error('Error editing user:', error);
      notify('Error updating user.', 'critical');
    }
  };

  const handleRemoveUserClick = (user) => {
    if (!hasPermission('user_manage_*')) return;
    setSelectedUser(user);
    setIsUserConfirmationDialogOpen(true);
  };

  const handleUserConfirmationDialogConfirm = async () => {
    setIsUserConfirmationDialogOpen(false);
    try {
      const response = await api.deleteUser(selectedUser.user_id);
      console.log(response);
      notify(`User ${selectedUser.username} deleted successfully`, 'normal');

      const updatedUsers = await api.getUsersByAccountId(selectedAccount?.account_id);
      setUsers(updatedUsers);

      setSelectedUser(null);
    } catch (error) {
      console.error('Error removing user:', error);
      notify(`Error deleting user with ID ${selectedUser.user_id}: ${error.message}`, 'critical');
    }
  };

  return (
    <RuxCard style={{ width: '100%' }}>
      <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{`Users for ${selectedAccount ? `${selectedAccount.account_name} Account` : 'Account'}`}</span>
        {hasPermission('user_manage_*') && (
          <RuxButton secondary="" onClick={openAddUserDialog}>
            Add User
          </RuxButton>
        )}
      </div>

      <RuxTable>
        <RuxTableHeader>
          <RuxTableHeaderRow>
            <RuxTableHeaderCell>Users</RuxTableHeaderCell>
            <RuxTableHeaderCell>Role</RuxTableHeaderCell>
            <RuxTableHeaderCell></RuxTableHeaderCell>
          </RuxTableHeaderRow>
        </RuxTableHeader>

        <RuxTableBody>
          {users
            .filter((user) => user.account_id === selectedAccount?.account_id)
            .sort((a, b) => parseInt(a.user_id) - parseInt(b.user_id))
            .map((user, i) => (
              <RuxTableRow key={i} style={{ cursor: 'pointer' }}>
                <RuxTableCell>{user.username}</RuxTableCell>
                <RuxTableCell>{user.role_name}</RuxTableCell>
                <RuxTableCell style={{ textAlign: 'right' }}>
                  {hasPermission('user_manage_*') && (
                    <>
                      <RuxButton
                        size="small"
                        secondary
                        icon-only
                        icon="create"
                        borderless
                        onClick={() => openEditUserDialog(user)}
                      />
                      <RuxButton
                        size="small"
                        secondary
                        icon-only
                        icon="remove-circle-outline"
                        borderless
                        onClick={() => handleRemoveUserClick(user)}
                      />
                    </>
                  )}
                </RuxTableCell>
              </RuxTableRow>
            ))}

          {users.filter((user) => user.account_id === selectedAccount?.account_id).length <= 0 && (
            <RuxTableRow>
              <RuxTableCell>
                No users created
              </RuxTableCell>
            </RuxTableRow>
          )}
        </RuxTableBody>
      </RuxTable>

      <RuxDialog id="add-user-dialog" open={isAddUserDialogOpen} onRuxclosed={closeAddUserDialog}>
        <span slot="header">Add User</span>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <RuxInput label="Username" placeholder="Username" name="username" onRuxinput={handleNewUserFormChange} value={newUserForm.username} />
          <RuxInput label="Password" placeholder="Password" name="password" type="password" onRuxinput={handleNewUserFormChange} value={newUserForm.password} />
          <RuxSelect label="Choose Role" input-id="user-role" label-id="user-role" name="role_name" size="small" onRuxchange={handleNewUserFormChange} value={newUserForm.role_name}>
            <RuxOption value="" label="Select an option"></RuxOption>
            {roles.map((role) => (
              <RuxOption key={role.name} value={role.name} label={role.name}></RuxOption>
            ))}
          </RuxSelect>
        </div>
        <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <RuxButton id="cancel" secondary="" onClick={closeAddUserDialog}>
            Cancel
          </RuxButton>
          <RuxButton id="confirm" onClick={handleAddUserClick}>
            Confirm
          </RuxButton>
        </div>
      </RuxDialog>

      <RuxDialog id="edit-user-dialog" open={isEditUserDialogOpen} onRuxclosed={closeEditUserDialog}>
        <span slot="header">Edit User</span>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {!editUserForm.userId && (
            <RuxInput
              label="Username"
              placeholder="Username"
              name="username"
              onRuxinput={handleEditUserFormChange}
              value={editUserForm.username}
            />
          )}
          <RuxInput
            label="Password"
            placeholder="Password"
            name="password"
            type="password"
            onRuxinput={handleEditUserFormChange}
            value={editUserForm.password}
          />
          <RuxSelect label="Choose Role" input-id="user-role" label-id="user-role" name="role_name" size="small" onRuxchange={handleEditUserFormChange} value={editUserForm.role_name}>
            <RuxOption value="" label="Select an option"></RuxOption>
            {roles.map((role) => (
              <RuxOption key={role.name} value={role.name} label={role.name}></RuxOption>
            ))}
          </RuxSelect>
        </div>
        <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <RuxButton id="cancel" secondary="" onClick={closeEditUserDialog}>
            Cancel
          </RuxButton>
          <RuxButton id="confirm" onClick={handleEditUserClick}>
            Confirm
          </RuxButton>
        </div>
      </RuxDialog>

      <RuxDialog
        id="user-confirmation-dialog"
        open={isUserConfirmationDialogOpen}
        onRuxclosed={() => setIsUserConfirmationDialogOpen(false)}
      >
        <span slot="header">Confirmation</span>
        <div>
          Are you sure you want to delete the <strong>{selectedUser?.username}</strong> user?
        </div>
        <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <RuxButton id="cancel" secondary="" onClick={() => setIsUserConfirmationDialogOpen(false)}>
            Cancel
          </RuxButton>
          <RuxButton id="confirm" onClick={handleUserConfirmationDialogConfirm}>
            Confirm
          </RuxButton>
        </div>
      </RuxDialog>
    </RuxCard>
  );
}

export default UserTable;