import api from './config/axiosConfig';


const PROFILE_API_BASE_URL = 'communication-profiles';

export const createProfile = (data) => {
    return api.post(PROFILE_API_BASE_URL, data);
};

export const getProfilesByObjectId = (objectId) => {
    const url = `${PROFILE_API_BASE_URL}/?object_id=${objectId}`;
    return api.get(url);
};

export const updateProfile = (profileId, data) => {
    const url = `${PROFILE_API_BASE_URL}/${profileId}`;
    return api.put(url, data);
};

export const deleteProfile = (profileId) => {
    const url = `${PROFILE_API_BASE_URL}/${profileId}`;
    return api.delete(url);
}; 
