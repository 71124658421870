import React, {useEffect} from 'react';
import AlertsTable from '../systems/alertManagement/AlertsTable'

const AlertsScreen = (props) => {
    useEffect(() => {
        document.title = "GCN Alerts";
    }, []);

    return (
        
        <div style={{
            display: 'grid',
            gridTemplateColumns: '100%',
            justifyContent: 'space-evenly',
            margin: '12px',
            gap: '12px'
        }}>

            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    <div slot="header">Alerts</div>
                    <AlertsTable/>
                </rux-container>
            </div>
        </div>
    );
}

export default AlertsScreen