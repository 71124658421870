import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import { RuxCheckbox, RuxButton, RuxDialog, RuxInput, RuxIcon } from '@astrouxds/react';
import { getAlerts, acknowledgeAlert } from '../../apis/alertApi'
import { getStations } from '../../apis/stationApi';
import {notify} from "../../libs/notificationSystem/notificationManager";
import { useAuth } from "../../auth/AuthContext";
import Pagination from '../../components/Pagination';
import StatusIndicator from '../../components/StatusIndicator';


const AlertsTable = () => {
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
    const [payloadDialogData, setPayloadDialogData] = useState(null)
    const [isViewExpanded, setIsViewExpanded] = useState(false)
    const [isGrouping, setIsGrouping] = useState(false)
    const [alerts, setAlerts] = useState([]) // {name, payload, time, system..., SELECTED}
    // selected filters
    const [systems, setSystems] = useState([]) // {name: "", selected: ""}
    const [levels, setLevels] = useState([
        {
            name: "debug",
            selected: false
        },
        {
            name: "info",
            selected: false
        },
        {
            name: "success",
            selected: false
        },
        {
            name: "caution",
            selected: true
        },
        {
            name: "warning",
            selected: true
        },
        {
            name: "critical",
            selected: true
        }
    ]) // {name, selected}
    const [stations, setStations] = useState([]) // {station_id, name, selected}
    const [searchString, setSearchString] = useState("")

    const [pageInfo, setPageInfo] = useState({
        page: 0,
        perPage: 100
    })
    const [totalEntries, setTotalEntries] = useState(0)
    
    const { hasPermission } = useAuth();

    const systemsRef = useRef(systems);
    const levelsRef = useRef(levels);
    const stationsRef = useRef(stations);

    useEffect(() => {
        systemsRef.current = systems;
        levelsRef.current = levels;
        stationsRef.current = stations;
    }, [systems, levels, stations]);

    useEffect(() => {
        const loadAlerts = async () => {
            try {
                const res = await getAlerts(systemsRef.current, levelsRef.current, stationsRef.current, searchString, pageInfo.page*pageInfo.perPage, pageInfo.perPage, isGrouping, true);
                setAlerts(prevAlerts => res.data.map(resAlert => ({
                    ...resAlert,
                    selected: prevAlerts.find(a => {
                        if(a.alert_id === resAlert.alert_id) {
                            return true
                        }
                        else return false
                    })?.selected || false
                })));
                setTotalEntries(res.entries)
            } catch (error) {
                console.error('Error loading alerts:', error);
            }
        };

        loadAlerts()

        const interval = setInterval(async () => {
            await loadAlerts()
        }, 20000);

        return () => clearInterval(interval);
    }, [pageInfo, isGrouping])

    useEffect(() => {
        const loadStations = async () => {
            try {
                const res = await getStations();
                setStations(prevStations => {
                    const existingIds = new Set(prevStations.map(s => s.station_id));
                    const newStations = res.filter(resStation => !existingIds.has(resStation.station_id))
                        .map(resStation => ({
                            station_id: resStation.station_id,
                            station_name: resStation.station_name,
                            selected: false
                        }));
                    return [...prevStations, ...newStations];
                });
            } catch (error) {
                console.error('Error loading stations:', error);
            }
        };

        const loadSystems = () => {
            const newSystems = new Set(systems.map(system => system.name));
        
            alerts.forEach(alert => {
                if (!newSystems.has(alert.system)) {
                    newSystems.add(alert.system);
                }
            });
        
            setSystems(prevSystems => [
                ...prevSystems,
                ...Array.from(newSystems).filter(systemName => !prevSystems.some(system => system.name === systemName)).map(systemName => ({ name: systemName, selected: false }))
            ]);
        };

        loadStations()
        loadSystems()

        
    }, [alerts])

    const handleAlertsAcknowledgeClick = async () => {
        try {
            setAlerts(alerts.filter(a => {
                if(a.selected) {
                    acknowledgeAlert({
                        alert_id: a.alert_id,
                        group: isGrouping
                    });
                    return false
                }
                return true
            }))
            console.log(`Selected alerts acknowledged successfully`);
        } catch (error) {
            console.error(`Error acknowledging alerts: ${error.message}`);
            notify(`Error acknowledging alerts: ${error.message}`, 'critical');
        }
    };

    const handleSingleAlertAcknowledgeClick = async (alert_id) => {
        try {
            setAlerts(alerts.filter(a => {
                if(a.alert_id === alert_id) {
                    acknowledgeAlert({
                        alert_id: alert_id,
                        group: isGrouping
                    });
                    return false
                }
                return true
            }))
            console.log(`Alert ${alert_id} acknowledged successfully`);
        } catch (error) {
            console.error(`Error acknowledging alert ${alert_id}: ${error.message}`)
            notify(`Error acknowledging alert ${alert_id}: ${error.message}`)
        }
    }

    // Enables selecting alert checkboxes
    const toggleSelected = (a) => {
        setAlerts(alerts.map(alertItem => {
            if(alertItem.alert_id === a.alert_id) {
                return {...alertItem, selected: !alertItem.selected}
            }
            else {
                return alertItem
            }
        }))
    }
    // Select all alerts
    const selectAll = () => {
        setAlerts(alerts.map(a => {
            return {...a, selected: true}
        }))
    }
    // Sends request with updated data
    const updateFilter = async () => {
        setIsFilterDialogOpen(false)
        try {
            const res = await getAlerts(systems, levels, stations, searchString, pageInfo.page*pageInfo.perPage, pageInfo.perPage, isGrouping, true);
            setAlerts(prevAlerts => res.data.map(resAlert => ({
                ...resAlert,
                selected: prevAlerts.find(a => {
                    if(a.alert_id === resAlert.alert_id) {
                        return true
                    }
                    else return false
                })?.selected || false
            })));
            setTotalEntries(res.entries)
            setPageInfo(oldPageInfo => ({
                ...oldPageInfo,
                page: 0
            }))
        } catch (error) {
            console.error('Error loading alerts:', error);
        }
    }

    const handleFilterClick = (property, id) => {
        if(property === "station") {
            setStations(prevStations => prevStations.map(station => {
                if(station.station_id === id) {
                    return {
                        ...station,
                        selected: !station.selected
                    }
                } else return station
            }))
        } else if(property === "system") {
            setSystems(prevSystems => prevSystems.map(system => {
                if(system.name === id) {
                    return {
                        ...system,
                        selected: !system.selected
                    }
                } else return system
            }))
        } else if(property === "level") {
            setLevels(prevLevels => prevLevels.map(level => {
                if(level.name === id) {
                    return {
                        ...level,
                        selected: !level.selected
                    }
                } else return level
            }))
        }
    }

    const handleSearchClick = () => {
        updateFilter()
    }
    const handleSearchChange = e => {
        console.log(e.target.value)
        setSearchString(e.target.value)
    }

    const handleShowPayloadClick = a => {
        setPayloadDialogData(a)
    }

    const handleIsGroupingClick = () => {
        setIsGrouping(oldIsGrouping => !oldIsGrouping)
    }

    const renderStatusIcon = (a) => {
        const status_map = {
            debug: 'off',
            info: 'standby',
            success: 'normal',
            caution: 'caution',
            warning: 'serious',
            critical: 'critical'
        };
    
        return status_map[a.level];
    }
    const renderLevelStatus = (level) => {
        let status = 'off';
    
        switch (String(level).trim()) {
            case 'error':
                status = 'critical';
                break;
            case 'warning':
                status = 'serious';
                break;
            case 'caution':
                status = 'caution';
                break;
            case 'success':
                status = 'normal';
                break;
            case 'info':
                status = 'standby';
                break;
            case 'debug':
                status = 'off';
    
        }
    
        return <StatusIndicator level={status} title={level} />
    }

    const timeToNow = (time) => {
        const now = new Date();
        const alertTime = new Date(time);
        const isToday = moment(alertTime).utc().format('DD.MM.YYYY') === moment(now).utc().format('DD.MM.YYYY');

        let time1
        if(isToday) {
            time1 = moment(alertTime).fromNow()
        } else {
            time1 = moment(alertTime).utc().format('DD.MM.YYYY')
        }
        const time2 = moment(alertTime).utc().format('HH:mm:ss')

        return (
            <span>
                {time1}
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                    {time2}
                </span>
            </span>
        );
    };

    const displayVar = (v, level = 0) => {
        if (typeof v === 'object' && v !== null) {
            const keys = Object.keys(v);
            let output = `(${typeof v})` + "\n";
            for (let i in keys) {
            const key = keys[i];
            const value = v[key];
            output += ("\t").repeat(level) + key + ': ' + displayVar(value, level + 1) + "\n"
            }
            return output;
        } else {
            return v;
        }
    }

    return (
        <div>
            <div style={{marginBottom: "20px"}}>
                <div style={{display: 'inline-flex', alignItems: 'centre'}}>
                    <RuxButton onClick={handleAlertsAcknowledgeClick} style={{marginRight: "20px"}}>Acknowledge Selection</RuxButton>
                    <RuxButton onClick={() => setIsFilterDialogOpen(true)} style={{marginRight: "20px"}} >Filter</RuxButton>
                    <RuxInput type="text" placeholder="Search title" value={searchString} onRuxchange={handleSearchChange} size="medium" style={{display: 'inline-block'}}></RuxInput>
                    <RuxButton onClick={handleSearchClick} style={{marginRight: "20px"}}>Search</RuxButton>
                    <RuxCheckbox label="Group alerts" checked={isGrouping} onRuxchange={handleIsGroupingClick} style={{margin: 'auto 0'}} />

                </div>
                <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} totalEntries={totalEntries} />
            </div>
            <RuxDialog open={isFilterDialogOpen} header='Filter Alerts'>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                        <span style={{ fontWeight: 'bold', marginBottom: '10px' }}>Stations</span>
                        {stations.map((station, index) => (
                            <RuxCheckbox
                                key={index}
                                name={station.station_name}
                                label={station.station_name}
                                checked={station.selected}
                                onRuxchange={() => handleFilterClick("station", station.station_id)}
                            />
                        ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                        <span style={{ fontWeight: 'bold', marginBottom: '10px' }}>Systems</span>
                        {systems.map((system, index) => (
                            <RuxCheckbox
                                key={index}
                                name={system.name}
                                label={system.name}
                                checked={system.selected}
                                onRuxchange={() => handleFilterClick("system", system.name)}
                            />
                        ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                        <span style={{ fontWeight: 'bold', marginBottom: '10px' }}>Levels</span>
                        {levels.map((level, index) => (
                            <RuxCheckbox
                                key={index}
                                name={level.name}
                                label={level.name}
                                checked={level.selected}
                                onRuxchange={() => handleFilterClick("level", level.name)}
                            />
                        ))}
                    </div>
                </div>
                <div slot='footer' onClick={updateFilter}>
                    <RuxButton style={{ float: 'right' }} id="confirm">
                        Confirm
                    </RuxButton>
                </div>
            </RuxDialog>
            <RuxDialog open={payloadDialogData !== null} header='Alert Detail'>
                {payloadDialogData && <pre style={{fontSize: '0.75em'}}>{displayVar(payloadDialogData.payload)}</pre>}
                <div slot='footer' onClick={() => setPayloadDialogData(null)}>
                    <RuxButton secondary style={{ float: 'right' }} id="confirm">
                        Close
                    </RuxButton>
                </div>
            </RuxDialog>
            <rux-table>
                <rux-table-body>
                    <rux-table-header-row selected="false">
                        {hasPermission('alerts_manage_all') && <rux-table-header-cell><a onClick={selectAll}>
                            Select All
                        </a></rux-table-header-cell>}
                        <rux-table-header-cell>System</rux-table-header-cell>
                        <rux-table-header-cell>Level</rux-table-header-cell>
                        <rux-table-header-cell>Title</rux-table-header-cell>
                        <rux-table-header-cell>Time</rux-table-header-cell>
                        <rux-table-header-cell></rux-table-header-cell>
                        {/* {isViewExpanded && <rux-table-header-cell>Payload</rux-table-header-cell>} */}
                    </rux-table-header-row>
                    {alerts.map((a, i) => (
                        <rux-table-row selected="false" key={i}>
                            {hasPermission('alerts_manage_all') && (<rux-table-cell>
                                <RuxCheckbox style={{marginRight: "8px"}}
                                    id={a.alert_id}
                                    checked={a.selected}
                                    onRuxchange={() => toggleSelected(a)}
                                />
                                <RuxButton secondary size="small" onClick={() => handleSingleAlertAcknowledgeClick(a.alert_id)}>Ack.</RuxButton>
                            </rux-table-cell>)}
                            <rux-table-cell>
                                {a.system  || 'n/a'}
                                <br/>
                                <span style={{
                                    fontSize: '0.8em',
                                    color: "gray"
                                }}>{stations.find(station => station.station_id === a.station_id)?.station_name || 'n/a'}</span>
                            </rux-table-cell>
                            {/* <rux-table-cell>
                                <rux-status status={renderStatusIcon(a)} style={{ marginRight: '8px', display: 'inline-block' }}></rux-status>
                                {a.level} 
                            </rux-table-cell> */}
                            <rux-table-cell>{renderLevelStatus(a.level)}</rux-table-cell>
                            <rux-table-cell>{a.title} {(isGrouping && a.count > 1) ? `(${a.count})` : ""}</rux-table-cell>
                            <rux-table-cell>{timeToNow(a.time)}</rux-table-cell>
                            <rux-table-cell><RuxButton secondary icon-only icon="info" borderless onClick={() => handleShowPayloadClick(a)}/></rux-table-cell>
                            {/* {isViewExpanded && <rux-table-cell style={{overflow: "hidden"}}>
                                <pre style={{fontSize: '0.75em'}}>{JSON.stringify(a.payload, null, 2)}</pre>
                            </rux-table-cell>} */}
                        </rux-table-row>
                    ))}
                </rux-table-body>
            </rux-table>
        </div>
    )
}

export default AlertsTable