import React, { useEffect, useState } from 'react';
import {
    RuxClock,
    RuxGlobalStatusBar,
    RuxIcon,
    RuxMenu,
    RuxMenuItem,
    RuxMenuItemDivider,
    RuxMonitoringIcon,
    RuxPopUp
} from "@astrouxds/react";
import {Link} from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { notify } from '../libs/notificationSystem/notificationManager';
import { getCounts } from '../apis/systemApi';


export default function HeaderGlobal() {
    const { user, logout, hasPermission } = useAuth();
    const [alertCount, setAlertCount] = useState({
        count: 0,
        icon: "assistant",
        status: "standby"
    })
    const [bookingCount, setBookingCount] = useState({
        count: 0,
        status: "off"
    })
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)

    useEffect(() => {

        const alertsToCount = (alertBody) => {
            if(alertBody.critical) {
                return {
                    count: alertBody.critical,
                    icon: "announcement",
                    status: "critical"
                }
            } else if(alertBody.caution || alertBody.warning) {
                return {
                    count: alertBody.caution + alertBody.warning,
                    icon: "announcement",
                    status: "caution"
                }
            } else {
                return {
                    count: alertBody.info + alertBody.success,
                    icon: "assistant",
                    status: "standby"
                }
            }
        }
        const bookingsToCount = (bookingBody) => {
            return {
                count: bookingBody.ongoing || bookingBody.upcoming,
                status: bookingBody.ongoing ? "normal" : (bookingBody.upcoming ? "standby" : "off")
            }
        }

        const count = async () => {
            if(hasPermission("alerts_view_all") || hasPermission("booking_view_*")) {
                try {
                    const counts = await getCounts()
                    if(hasPermission("alerts_view_all")) {
                        setAlertCount(alertsToCount(counts.alerts))
                    }
                    if(hasPermission("booking_view_*")) {
                        setBookingCount(bookingsToCount(counts.bookings))
                    }
                } catch (error) {
                    console.error('Error requesting alert and booking counts:', error);
                    notify('Error requesting alert and booking counts.', 'critical');
                }
            }
        }

        count()
        const countInterval = setInterval(count, 10*1000)

        return () => clearInterval(countInterval)
    }, [user])

    const handleLogout = () => {
        logout();
    };


    return (
        <RuxGlobalStatusBar include-icon="false" username={user?.username} app-domain="GCN"
                        app-name={process.env.REACT_APP_SERVER_NAME} app-version=""
                        style={{ position: 'relative' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <div>
                {/* Left-aligned content */}
                {/* Add any left-aligned content here */}
                </div>

                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <RuxClock />
                </div>

                {user && <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Right-aligned content */}
                    {hasPermission("booking_view_*") && <Link to="/bookings" style={{margin: "0 10px"}}><RuxMonitoringIcon icon="satellite-transmit" label="Observations" status={bookingCount.status} notifications={bookingCount.count} style={{display: 'inline-block', margin: '5px'}}/></Link>}
                    {hasPermission("alerts_view_all") && <Link to="/alerts" style={{margin: "0 10px"}}><RuxMonitoringIcon icon={alertCount.icon} label="Alerts" status={alertCount.status} notifications={alertCount.count} style={{display: 'inline-block', margin: '5px'}}/></Link>}
                    <RuxPopUp open={isPopUpOpen} onRuxpopupopened={() => setIsPopUpOpen(true)} onRuxpopupclosed={() => setIsPopUpOpen(false)} style={{margin: "0 10px"}}>
                        <RuxIcon icon="apps" slot="trigger" />
                        <RuxMenu onClick={() => setIsPopUpOpen(false)}>
                            <div>
                                <Link to="/schedule">
                                    <RuxMenuItem>Schedule</RuxMenuItem>
                                </Link>
                                <Link to="/bookings">
                                    <RuxMenuItem>Bookings</RuxMenuItem>
                                </Link>
                                <RuxMenuItemDivider/>
                                {hasPermission("object_view_all") && <Link to="/objects">
                                    <RuxMenuItem>Object Management</RuxMenuItem>
                                </Link>}
                                {hasPermission("station_view_all") && <Link to="/stations">
                                    <RuxMenuItem>Station Management</RuxMenuItem>
                                </Link>}
                                {hasPermission("template_manage_all") && <Link to="/templates">
                                    <RuxMenuItem>Template Management</RuxMenuItem>
                                </Link>}
                                {hasPermission("alerts_view_all") && <Link to="/alerts">
                                    <RuxMenuItem>Alerts Overview</RuxMenuItem>
                                </Link>}
                                {(hasPermission("transmission_manage_all") || hasPermission("reception_manage_all")) && <Link to="/sandbox">
                                    <RuxMenuItem>Sandbox</RuxMenuItem>
                                </Link>}
                                <Link to="/user_management">
                                    <RuxMenuItem>User Management</RuxMenuItem>
                                </Link>
                                <Link to="/map">
                                    <RuxMenuItem>Map</RuxMenuItem>
                                </Link>
                                <RuxMenuItemDivider />
                                <RuxMenuItem onClick={handleLogout}>Logout</RuxMenuItem>
                            </div>
                        </RuxMenu>
                    </RuxPopUp>
                </div>}
            </div>
        </RuxGlobalStatusBar>
    )
}