import React, { useState, useEffect } from 'react';
import { getOpenReceptions, stopReception, activateReception } from '../../apis/receptionApi';
import { getProfilesByObjectId } from '../../apis/profileApi';
import { RuxTable, RuxTableHeader, RuxTableHeaderRow, RuxTableBody, RuxTableRow, RuxTableCell, RuxTableHeaderCell, RuxButton, RuxDialog, RuxInput, RuxSelect, RuxOption } from '@astrouxds/react';
import { useAuth } from "../../auth/AuthContext";
import { Link } from "react-router-dom";
import { notify } from "../../libs/notificationSystem/notificationManager";

const ReceptionTable = ({ booking, label }) => {
    const [receptions, setReceptions] = useState([]);
    const { hasPermission } = useAuth();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [receptionFormData, setReceptionFormData] = useState({
        booking_id: '',
        comm_profile_id: ''
    });
    const [profiles, setProfiles] = useState([])

    useEffect(() => {
        const handleLoadReceptions = async () => {
            if (!hasPermission('reception_manage_all')) return;
            try {
                if (!booking) {
                    const receptionsData = await getOpenReceptions();
                    receptionsData.sort((a, b) => a.port - b.port);
                    setReceptions(receptionsData);
                } else {
                    const receptionsData = await getOpenReceptions();
                    const filteredReceptions = receptionsData.filter(reception => reception.booking_id === booking.booking_id);
                    filteredReceptions.sort((a, b) => a.port - b.port);
                    setReceptions(filteredReceptions);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        handleLoadReceptions();
        
        const handleLoadProfiles = async () => {
            if(!hasPermission("object_profile_view_all")) return
            try {
                if(booking) {
                    const profileData = await getProfilesByObjectId(booking.object.object_id)
                    setProfiles(profileData)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }
        handleLoadProfiles()
        
        const interval = setInterval(() => {
            handleLoadReceptions()
            isDialogOpen && handleLoadProfiles()
        }, 1000);
        return () => clearInterval(interval);
    }, [booking, hasPermission, isDialogOpen]);

    const handleDeleteObjectClick = async (reception_id) => {
        if (!hasPermission('reception_manage_all')) return;

        try {
            await stopReception(reception_id);
            console.log('Reception stopped successfully');
            notify(`Reception stopped successfully`, 'normal');
        } catch (error) {
            console.error(`Error stopping reception: ${error.message}`);
            notify(`Error stopping reception: ${error.message}`, 'critical');
        }
    };

    const openActivateReceptionDialog = () => {
        setReceptionFormData({
            booking_id: booking?.booking_id || '',
            comm_profile_id: ''
        });
        setIsDialogOpen(true);
    };

    const closeActivateReceptionDialog = () => {
        setIsDialogOpen(false)
    };

    const handleReceptionFormChange = (e) => {
        const { name, value } = e.target;
        setReceptionFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleActivateReceptionConfirmClick = async () => {
        try {
            await activateReception(receptionFormData);
            notify('Reception activated successfully', 'normal');
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Error activating reception:', error);
            notify('Error activating reception.', 'critical');
        }
    };

    const transformSizeInBytes = (size) => {
        if(size == undefined) {
            return 'N/A'
        } else if(size < 1000) {
            return `${size}B`
        } else if(size < 1000000) {
            return `${(size/1000).toPrecision(3)}kB`
        } else {
            return `${(size/1000000).toPrecision(3)}MB`
        }
    }

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '100%',
            justifyContent: 'space-evenly',
            margin: '12px',
            gap: '12px'
        }}>
            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>{label}</div>
                        <RuxButton onClick={openActivateReceptionDialog} secondary="">Activate Reception</RuxButton>
                    </div>
                    <RuxTable>
                        <RuxTableBody>
                            <RuxTableHeaderRow selected={false}>
                                <RuxTableHeaderCell>Booking</RuxTableHeaderCell>
                                <RuxTableHeaderCell>Reception ID</RuxTableHeaderCell>
                                <RuxTableHeaderCell>Port</RuxTableHeaderCell>
                                <RuxTableHeaderCell>Size</RuxTableHeaderCell>
                                <RuxTableHeaderCell></RuxTableHeaderCell>
                            </RuxTableHeaderRow>
                            {receptions.map(item => (
                                <RuxTableRow key={item.reception_id}>
                                    <RuxTableCell>
                                        <Link to={`/bookings/${item.booking_id}`}>
                                            <rux-button secondary icon-only icon="launch" borderless />
                                        </Link>
                                    </RuxTableCell>
                                    <RuxTableCell>
                                        {item.reception_id}
                                        <a href={`${process.env.REACT_APP_API_BASE_URL}reception/${item.reception_id}/stream`} target="_blank"><rux-button secondary icon-only icon="launch" borderless /></a>
                                    </RuxTableCell>
                                    <RuxTableCell>{item.port}</RuxTableCell>
                                    <RuxTableCell>{transformSizeInBytes(item.size)}</RuxTableCell>
                                    <RuxButton size="small" secondary icon-only icon="remove-circle-outline"
                                        borderless
                                        onClick={() => handleDeleteObjectClick(item.reception_id)} />
                                </RuxTableRow>
                            ))}
                        </RuxTableBody>
                    </RuxTable>
                </rux-container>

            </div>

            <RuxDialog id="assign-object-dialog" open={isDialogOpen} onRuxclosed=''>
                <span slot="header">Activate Reception</span>
                <div>
                    {booking ? (
                        <>
                        <div>
                            <label>Booking</label><br />
                            <span>{booking.booking_id}</span>
                        </div>
                        <RuxSelect 
                            style={{marginTop: '15px'}}
                            size="small" 
                            name="comm_profile_id" 
                            label="Communication Profile" 
                            onRuxchange={handleReceptionFormChange} 
                            value={receptionFormData.comm_profile_id}>
                            <RuxOption value={""} label="No profile" />
                            { profiles.map(profile => (
                                <RuxOption key={profile.profile_id} value={profile.profile_id} label={profile.title} />
                            ))}
                        </RuxSelect>
                        </>
                    ) : (
                        <>
                        <RuxInput
                            label="Booking"
                            name="booking_id"
                            value={receptionFormData.booking_id}
                            onRuxinput={handleReceptionFormChange}
                        />
                        <RuxInput
                            style={{marginTop: '15px'}}
                            label="Communication Profile"
                            name="comm_profile_id"
                            value={receptionFormData.comm_profile_id}
                            onRuxinput={handleReceptionFormChange}
                        />
                        </>
                    )}
                </div>
                <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <RuxButton id="cancel" secondary="" onClick={closeActivateReceptionDialog}>
                        Cancel
                    </RuxButton>
                    <RuxButton id="confirm" onClick={handleActivateReceptionConfirmClick}>
                        Confirm
                    </RuxButton>
                </div>
            </RuxDialog>
        </div>
    );
}

export default ReceptionTable;