import React, { useState, useEffect } from 'react';
import { RuxTable, RuxTableHeader, RuxTableHeaderRow, RuxTableBody, RuxTableRow, RuxTableCell, RuxInput, RuxButton } from '@astrouxds/react';
import {getStations} from '../../apis/stationApi';

function StationDetail({ station: initialStation }) {
    console.log(initialStation)
    const [station, setStation] = useState(initialStation || null);
    const [newStation, setNewStation] = useState('');

    useEffect(() => {
        if (initialStation && initialStation !== station) {
            setStation(initialStation);
        }
    }, [initialStation, station]);

    const handleInputChange = (event) => {
        setNewStation(event.target.value);
    };

    const handleLoadStation = async () => {
        try {
           const stationData = await getStations(newStation);
           setStation(stationData);
       } catch (error) {
           console.error('Error:', error);
        }
    };

    const renderStatusIcon = (station) => {
        return station.connected ? 'normal' : 'off';
    };

    const renderStationTable = () => {
        if (!station) {
            return null;
        }

        const stationInfo = [
            {label: 'Station Name', value: station.station_name  || 'N/A'},
            { label: 'Status', value:
                <div style={{ display: 'flex' }}>
                    <rux-status style={{ marginRight: '3px' }} status={renderStatusIcon(station) || 'N/A'}></rux-status>
                    {station.connected ? 'Connected' : 'Disconnected'}
                </div>
            },
            {label: 'Address',      value: station.address|| 'N/A'},
            {label: 'MQTT Address', value: station.mqtt_address|| 'N/A'},
            {label: 'Altitude',     value: station.location.altitude || 'N/A'},
            {label: 'Latitude',     value: station.location.latitude || 'N/A'},
            {label: 'Longitude',    value: station.location.longitude || 'N/A'},
            {label: 'Port ID',      value: station.port_assignment_id}
        ];

        return (
            <div>
                <RuxTable>
                    <RuxTableHeader>
                        <RuxTableHeaderRow>
                            <td colSpan={2}> {station.station_id}</td>
                        </RuxTableHeaderRow>
                    </RuxTableHeader>
                    <RuxTableBody>
                        {stationInfo.map(item => (
                            <RuxTableRow key={item.label}>
                                <RuxTableCell>{item.label}</RuxTableCell>
                                <RuxTableCell>{item.value}</RuxTableCell>
                            </RuxTableRow>))}
                    </RuxTableBody>
                </RuxTable>
            </div>
        );
    };

    return (
        <div>
            {!initialStation &&
                <div style={{display: 'flex', gap: '10px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end', width: '100%', paddingBottom: '20px' }}>
                    <RuxInput type="text" style={{width: '80%'}} label="Station ID" value={newStation} onRuxchange={handleInputChange}/>
                    <RuxButton onClick={handleLoadStation}>Display</RuxButton>
                </div>
            }
            {renderStationTable()}
        </div>
    );
}

export default StationDetail;
