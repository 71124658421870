import React from "react";
import moment from "moment";

const CalendarLink = ({booking}) => {
    if(!booking.booking_id) return

    const time1 = moment(booking.time_start || booking.time_aos).utc().format('YYYYMMDDTHHmmss') + 'Z'
    const time2 = moment(booking.time_end || booking.time_los).utc().format('YYYYMMDDTHHmmss') + 'Z'
    const url = `https://google.com/calendar/event?action=TEMPLATE&text=${booking.object.name}%2F${booking.station.station_name}&dates=${time1}/${time2}&details=${
        `Satellite: [${booking.object.norad_id}] ${booking.object.name} %0A` +
        `Station: ${booking.station.station_name} %0A` +
        `${window.location.protocol}//${window.location.host}/bookings/${booking.booking_id}`
    }`
    return <a href={url} target="_blank"><img src='/Google_Calendar_icon_(2020).png' alt='Add to Google Calendar' style={{
        height: '15px',
        paddingLeft: '5px'
    }}/></a>
}

export default CalendarLink