import React, { useEffect, useState, useRef } from "react";
import { RuxButton, RuxContainer, RuxInput, RuxOption, RuxSelect, RuxTree, RuxTreeNode, RuxCheckbox } from "@astrouxds/react";
import "./PassPlan.css";
import SearchCommands from "./SearchCommands/SearchCommands";
import * as api from "../../../apis/commandApi";
import { useAuth } from "../../../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { notify } from "../../../libs/notificationSystem/notificationManager";

const UniversalComponent = ({ booking, station, isFromStation, templateManagementCommandList, setTemplateManagementCommandList, selectedTemplate, isCreatingTemplate, templateNameInput, setTemplateNameInput, setSelectedTemplate, setIsCreatingTemplate, saveTemplateCaller, loadTemplateCommandsCaller, saveBookingCommandsCaller, executeButtonClickCaller, displayStatus, displayRelative, runAllCommandsCaller, runAllCommandsAtTimesCaller, label }) => {
    const { hasPermission } = useAuth();
    const navigate = useNavigate()
    const [commandList, setCommandList] = useState([]);
    const [stationCommandList, setStationCommandList] = useState([]);
    const [command, setCommand] = useState({});
    const [inProgressCommands, setInProgressCommands] = useState([]);
    const [commandTemplates, setCommandTemplates] = useState([]);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
    const [isExecuting, setIsExecuting] = useState(false);

    useEffect(() => {
        console.log('Command List:', commandList);
    }, [commandList]);

    useEffect(() => {
        const loadStationCommands = async () => {
            try {

                const stationCommand = await api.getStationCommands() || [];
                console.log(stationCommand);
                setStationCommandList(stationCommand);

            } catch (error) {
                console.error('Error loading station commands:', error);
            }
        };
        loadStationCommands();
    }, []);

    useEffect(() => {
        const loadCommandTemplates = async () => {
            try {
                const templates = await api.getCommandTemplates() || [];
                setCommandTemplates(templates);
            } catch (error) {
                console.error('Error loading command templates:', error);
            }
        };
        loadCommandTemplates();
    }, []);

    const getDefaultValue = (template, commandId, paramName) => {
        const step = template?.steps.find((step) => step.command_id === commandId);
        const templateParam = step?.parameters.find((param) => param.name === paramName);
        if (templateParam && 'default' in templateParam && paramName in templateParam.default) {
            if (templateParam.type === 'BOOLEAN') {
                return String(templateParam.default[paramName]);
            }
            return templateParam.default[paramName].toString();
        }
        return '';
    };

    useEffect(() => {
        const addTemplateCommands = () => {
            setCommandList([]);

            const newCommands = (templateManagementCommandList || [])
                .filter((item) => item.origin === 'template')
                .map((item) => {
                    const matchingCommand = (stationCommandList || []).find(
                        (command) => command.command_id === item.command_id
                    );

                    if (matchingCommand) {
                        return {
                            ...matchingCommand,
                            uniqueId: Date.now() + Math.random(),
                            status: 'off',
                            parameters: matchingCommand.parameters.map((param) => ({
                                ...param,
                                value: item.parameters.find((templateParam) => templateParam.name === param.name)?.value || '',
                            })),
                            expanded: true,
                            executed: false,
                            isChecked: true,
                            relative_to: item.relative_to || '',
                            relative_time: item.relative_time !== undefined ? item.relative_time : 0,
                        };
                    }

                    return null;
                }).filter(Boolean);
            setCommandList((prevCommandList) => [...prevCommandList, ...newCommands]);
        };

        addTemplateCommands();
    }, [booking, stationCommandList, templateManagementCommandList]);

    const addToPassQueue = (commandListItem, isTemplateCommand = false) => {
        console.log("Adding to Pass Queue:", commandListItem);
        if (!commandListItem) return;

        const relative_to = isTemplateCommand ? '' : ''; // Default to "--select--" for new commands
        const relative_time = isTemplateCommand ? 0 : 0;

        const commandItemWithOption = {
            ...commandListItem,
            uniqueId: Date.now(),
            status: 'off',
            parameters: commandListItem.parameters.map(param => ({
                name: param.name || 'Unnamed',
                value: isTemplateCommand
                    ? getDefaultValue(commandTemplates[selectedTemplateIndex], commandListItem.command_id, param.name)
                    : param.value ? param.value.name : '',
                type: param.type || 'STRING',
                description: param.description || '',
            })),
            isTemplateCommand,
            expanded: true,
            executed: false,
            isChecked: true,
            relative_to,
            relative_time,
        };

        console.log('Adding to Command List:', commandItemWithOption);
        setCommandList(prevCommandList => [...prevCommandList, commandItemWithOption]);
    };

    const handleOptionChange = (e, index, isTemplateManagement) => {
        const selectedValue = e.target.value;

        const updatedList = (isTemplateManagement ? templateManagementCommandList : commandList).map(
            (item, i) =>
                i === index
                    ? {
                        ...item,
                        parameters: item.parameters.map((param) =>
                            param.type === 'BOOLEAN' ? { ...param, value: selectedValue } : param
                        ),
                    }
                    : item
        );

        isTemplateManagement
            ? setTemplateManagementCommandList(updatedList)
            : setCommandList(updatedList);
    };

    const handleInputChange = (e, index, paramName, commandId, uniqueId, isTemplateManagement) => {
        const updatedList = (isTemplateManagement ? templateManagementCommandList : commandList).map(
            (cmd) =>
                cmd.commandId === commandId && cmd.uniqueId === uniqueId
                    ? {
                        ...cmd,
                        parameters: cmd.parameters.map((param) =>
                            param.type !== 'select' && param.name === paramName
                                ? { ...param, value: e.target.value }
                                : param
                        ),
                    }
                    : cmd
        );

        isTemplateManagement
            ? setTemplateManagementCommandList(updatedList)
            : setCommandList(updatedList);
    };

    const handleExecuteButtonClick = async (item, index) => {
        const commandId = item.command_id;
        const commandKey = `${commandId}_${index}`;

        setInProgressCommands((prevCommands) => [...prevCommands, commandKey]);
        if (inProgressCommands.includes(commandKey)) return;

        const updatedCommandList = [...commandList];
        updatedCommandList[index].status = 'serious';
        setCommandList(updatedCommandList);

        const params = {};

        item.parameters.forEach((param) => {
            if (param.value !== undefined) {
                const paramValue = param.type === 'NUMBER' ? Number(param.value) : param.value;
                params[param.name] = paramValue;
            }
        });

        const requestBody = {
            command_id: commandId,
            ...(Object.keys(params).length > 0 && { params: params }),
        };

        console.log("Executing command:", requestBody);
        try {
            let response;
            if (isFromStation) {
                // Execute command to station
                response = await api.executeCommandToStation(station.station_id, requestBody);
            } else {
                // Execute command for booking
                response = await api.executeCommand(booking.booking_id, requestBody);
            }
            console.log('Command executed successfully:', requestBody, response);

            updatedCommandList[index].status = 'normal';
            updatedCommandList[index].disabled = true;
            updatedCommandList[index].executed = true;
            setCommandList(updatedCommandList);
        } catch (error) {
            console.error('Error executing command:', error);
            if (error.response) {
                console.log('Error response details:', error.response.data, error.response.status);
            }
            const responseCodeNumber = error.response ? error.response.status : null;

            console.log('HTTP Status Code:', responseCodeNumber);

            const status = responseCodeNumber === 200 ? 'normal' : 'critical';
            updatedCommandList[index].status = status;
            setCommandList(updatedCommandList);
        } finally {
            setInProgressCommands((prevCommands) =>
                prevCommands.filter((id) => id !== commandKey)
            );
        }
    };

    const handleTemplateChange = (index) => {
        console.log("Handling Template Change:", index);

        setSelectedTemplateIndex(index);
        const selectedTemplate = commandTemplates[index];

        if (selectedTemplate && selectedTemplate.steps) {
            const newCommandList = selectedTemplate.steps.map((templateStep) => {
                const matchingCommand = stationCommandList.find(
                    (command) => command.command_id === templateStep.command_id
                );

                if (matchingCommand) {
                    const newCommand = {
                        ...matchingCommand,
                        origin: 'template',
                        uniqueId: Date.now() + Math.random(),
                        status: 'off',
                        booking_id: booking?.booking_id,
                        parameters: matchingCommand.parameters.map((param) => {
                            const templateParam =
                                typeof templateStep.parameters === 'object'
                                    ? Object.entries(templateStep.parameters).map(([name, value]) => ({ name, value }))
                                    : [];

                            const matchedParam = templateParam.find((tParam) => tParam.name === param.name);

                            return {
                                ...param,
                                value: matchedParam?.value || '',
                            };
                        }),
                        expanded: true,
                        executed: false,
                        isChecked: true,
                        relative_to: templateStep.relative_to || '',
                        relative_time: templateStep.relative_time !== undefined ? templateStep.relative_time : '',
                    };

                    console.log("New Command:", newCommand);
                    return newCommand;
                }

                return null;
            }).filter(Boolean);
            setCommandList((prevCommandList) => [...prevCommandList, ...newCommandList]);
        }
    };

    useEffect(() => {
        const loadSavedCommands = async () => {
            try {
                const savedCommands = await api.getSavedBookingCommands(booking.booking_id) || [];
                console.log('Saved Commands:', savedCommands);
                const newCommandList = savedCommands.map((savedCommand) => {
                    const matchingCommand = stationCommandList.find(
                        (command) => command.command_id === savedCommand.command_id
                    );

                    if (matchingCommand) {
                        const relativeTime =
                            savedCommand.relative_time !== undefined
                                ? savedCommand.relative_time > 0
                                    ? `+${savedCommand.relative_time}`
                                    : savedCommand.relative_time.toString()
                                : '';

                        return {
                            ...matchingCommand,
                            origin: 'saved',
                            uniqueId: Date.now() + Math.random(),
                            status: 'off',
                            booking_id: booking.booking_id,
                            parameters: matchingCommand.parameters.map((param) => ({
                                ...param,
                                value:
                                    savedCommand.parameters[param.name] !== undefined
                                        ? savedCommand.parameters[param.name]
                                        : param.value || '',
                            })),
                            expanded: false,
                            executed: false,
                            isChecked: true,
                            relative_to: savedCommand.relative_to || '',
                            relative_time: relativeTime,
                        };
                    }
                    return null;
                }).filter(Boolean);

                setCommandList((prevCommandList) => [...prevCommandList, ...newCommandList]);
            } catch (error) {
                console.error('Error loading saved commands:', error);
            }
        };
        loadSavedCommands();
    }, [booking, stationCommandList]);

    const saveBookingCommands = async () => {
        try {
            const requestData = commandList.map((item, index) => {
                const templateStep = commandTemplates[selectedTemplateIndex]?.steps.find(
                    (step) => step.command_id === item.command_id
                );

                const defaultValues = {};
                item.parameters.forEach((param) => {
                    if (param.value !== undefined) {
                        defaultValues[param.name] =
                            param.type === 'NUMBER' ? Number(param.value) : param.value;
                    }
                });

                const relative_to = item.relative_to || '';
                const relative_time =
                    item.relative_time !== undefined
                        ? item.relative_time
                        : templateStep?.relative_time !== undefined
                            ? templateStep.relative_time
                            : 0;

                return {
                    command_id: item.command_id,
                    relative_to: relative_to,
                    relative_time: relative_time,
                    parameters: defaultValues,
                };
            });
            console.log('Final Request Data:', requestData);
            const bookingId = booking?.booking_id || '';

            const formattedData = requestData.map((item) => ({
                command_id: item.command_id,
                relative_to: item.relative_to || '',
                relative_time: Number(item.relative_time) || 0,
                parameters: item.parameters || {}
            }));

            console.log('Formatted Data:', formattedData);
            await api.saveCommandsForBooking({ bookingId: bookingId, steps: formattedData });
            console.log('Commands saved successfully');
            notify('Commands saved successfully', 'normal');
        } catch (error) {
            console.error('Error saving template commands:', error);
            notify('Error saving commands.', 'critical');
        }
    };

    const handleSaveTemplate = async () => {
        try {
            const templateId = selectedTemplate?.template_id;
            const updatedSteps = commandList.map((item) => {
                const templateStep = commandTemplates[selectedTemplateIndex]?.steps.find(step => step.command_id === item.command_id);

                const getDefaultValue = (param) => {
                    if (param.value !== undefined) {
                        return param.type === 'NUMBER' ? Number(param.value) : param.value;
                    }
                    return undefined;
                };

                const defaultValues = {};
                item.parameters.forEach((param) => {
                    defaultValues[param.name] = getDefaultValue(param);
                });

                const relative_to = item.relative_to || '';
                const relative_time =
                    item.relative_time !== undefined
                        ? item.relative_time
                        : templateStep?.relative_time !== undefined
                            ? templateStep.relative_time
                            : 0;

                return {
                    command_id: item.command_id,
                    relative_to: relative_to,
                    relative_time: Number(relative_time),
                    parameters: Object.fromEntries(
                        Object.entries(defaultValues).map(([name, value]) => [
                            name,
                            item.parameters.find(param => param.name === name)?.value !== undefined
                                ? item.parameters.find(param => param.name === name)?.value
                                : value,
                        ])
                    ),
                };
            });

            const templateName = templateNameInput;
            const templateData = {
                name: templateName,
                steps: updatedSteps,
            };

            if (isCreatingTemplate) {
                await api.createCommandTemplate(templateData);
                console.log('New template created successfully');
                notify('Template created successfully', 'normal');

                setSelectedTemplate(null);
                setCommandList([]);
                setTemplateNameInput('');
                setIsCreatingTemplate(false);
            } else {
                await api.editCommandTemplate(templateId, { template_id: templateId, ...templateData });
                console.log('Template updated successfully');
                notify('Template updated successfully', 'normal');
            }
            setSelectedTemplate(null);
            setCommandList([]);
            setTemplateNameInput('');
            navigate("/templates")
        } catch (error) {
            console.error('Error saving template:', error);
            notify('Error saving template.', 'critical');
        }
    };

    const removeRow = (index) => {
        setCommandList((prevCommandList) => {
            const updatedCommandList = [...prevCommandList];
            updatedCommandList.splice(index, 1);

            return updatedCommandList;
        });
    };

    const handleConfirmation = () => {
        if (selectedTemplateIndex !== null) {
            handleTemplateChange(selectedTemplateIndex);
        }
    };

    const calculateRelativeTime = (command, booking) => {
        if (!booking || !booking.time_aos || !booking.time_los) {
            console.error('Invalid booking object or missing AOS/LOS times.');
            return '';
        }

        const bookingAOS = new Date(booking.time_aos);
        const bookingLOS = new Date(booking.time_los);

        const isRelativeTimeValidNumber = !isNaN(command.relative_time) && isFinite(command.relative_time);

        if (!isRelativeTimeValidNumber) {
            return '';
        }

        if (command.relative_to === 'AOS') {
            const calculatedTimeInSeconds = (bookingAOS.getTime() + command.relative_time * 1000) / 1000;
            return formatTimeInSeconds(calculatedTimeInSeconds);
        }

        if (command.relative_to === 'LOS') {
            const calculatedTimeInSeconds = (bookingLOS.getTime() + command.relative_time * 1000) / 1000;
            return formatTimeInSeconds(calculatedTimeInSeconds);
        }
        return formatTime('');
    };

    const formatTimeInSeconds = (timeInSeconds) => {
        const calculatedTimeString = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
        return calculatedTimeString;
    };

    const formatTime = (time) => {
        if (time instanceof Date && !isNaN(time)) {
            return time.toISOString().substr(11, 8);
        } else {
            return '';
        }
    };

    const handleNodeToggle = (index) => {
        setCommandList(commandList.map((item, idx) => idx === index ? { ...item, expanded: !item.expanded } : item));
    };

    const handleRelativeToChange = (e, index, isTemplateManagement) => {
        const updatedList = (isTemplateManagement ? templateManagementCommandList : commandList).map(
            (item, i) => (i === index ? { ...item, relative_to: e.target.value } : item)
        );

        console.log('Updated List (relative_to):', updatedList);

        isTemplateManagement
            ? setTemplateManagementCommandList(updatedList)
            : setCommandList(updatedList);

        console.log('New templateManagementCommandList:', templateManagementCommandList);
    };

    const handleRelativeTimeChange = (e, index, isTemplateManagement) => {
        const updatedList = (isTemplateManagement ? templateManagementCommandList : commandList).map(
            (item, i) => (i === index ? { ...item, relative_time: e.target.value.trim() } : item)
        );

        console.log('Updated List (relative_time):', updatedList);

        isTemplateManagement
            ? setTemplateManagementCommandList(updatedList)
            : setCommandList(updatedList);

        console.log('New templateManagementCommandList:', templateManagementCommandList);
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const stopExecutionRef = useRef(false);

    const handleRunAllCommands = async () => {
        setIsExecuting(true);
        stopExecutionRef.current = false;

        for (let i = 0; i < commandList.length; i++) {
            if (stopExecutionRef.current) break;
            const command = commandList[i];
            if (!command.isChecked) {
                continue;
            }
            try {
                await handleExecuteButtonClick(command, i);
            } catch (error) {
                console.error(`Error executing command with ID: ${error.message}`);
            }
            if (commandList[i].status === 'critical') break;
            await delay(1000);
            if (stopExecutionRef.current) break;
        }
        setIsExecuting(false);
    };

    const handleStopExecution = () => {
        stopExecutionRef.current = true;
    };

    const handleIsCheckedChange = (index) => {
        const updatedList = commandList.map(
            (item, i) => (i === index ? { ...item, isChecked: !item.isChecked } : item)
        );
        setCommandList(updatedList);
    };

    const handleCheckboxChange = (index) => {
        handleIsCheckedChange(index);
    };

    const handleExpandAll = () => {
        const updatedList = commandList.map(item => ({
            ...item,
            expanded: true
        }));
        setCommandList(updatedList);
    };

    const handleCollapseAll = () => {
        const updatedList = commandList.map(item => ({
            ...item,
            expanded: false
        }));
        setCommandList(updatedList);
    };

    const handleRunAllAtTimes = () => {
        setIsExecuting(true);
        stopExecutionRef.current = false;

        const { time_aos, time_los } = booking;
        if (!time_aos || !time_los) {
            console.error("Missing AOS or LOS times in booking.");
            return;
        }

        const sortedTasks = commandList.map((command, index) => {
            const referenceTime = command.relative_to.toLowerCase() === 'aos' ? time_aos : time_los;
            const targetTime = new Date(referenceTime).getTime() + (command.relative_time * 1000);
            return { ...command, targetTime, index };
        }).sort((a, b) => a.targetTime - b.targetTime);

        const executeCommands = async () => {
            let now = new Date();
            now.setMilliseconds(0);

            for (let task of sortedTasks) {
                const { targetTime, index } = task;

                if (!task.executed && now.getTime() >= targetTime) {
                    try {
                        task.executed = true;
                        await handleExecuteButtonClick(task, index);
                        const updatedCommandList = [...commandList];
                        setCommandList(updatedCommandList);

                        if (updatedCommandList[index].status === 'critical') {
                            console.error(`Command ${task.command_id} execution stopped due to critical status.`);
                            stopExecutionRef.current = true;
                            break;
                        }
                    } catch (error) {
                        console.error(`Error executing command ${task.command_id}: ${error}`);
                        stopExecutionRef.current = true;
                        break;
                    }
                }
                if (stopExecutionRef.current) {
                    clearInterval(intervalId);
                    setIsExecuting(false);
                    return;
                }
            }
            const allExecuted = sortedTasks.every(task => task.executed);
            if (allExecuted) {
                console.log("All commands have been executed.");
                clearInterval(intervalId);
                setIsExecuting(false);
            }
        };
        const intervalId = setInterval(() => {
            console.log("Checking for command execution...");
            executeCommands();
            if (stopExecutionRef.current) {
                clearInterval(intervalId);
                setIsExecuting(false);
            }
        }, 500);
        console.log("Command execution check interval started.");
    };

    return (
        <rux-card>

            <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {label}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {hasPermission('booking_manage_*') && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {loadTemplateCommandsCaller && (
                                <RuxSelect
                                    value={selectedTemplateIndex}
                                    onRuxchange={(e) => setSelectedTemplateIndex(e.target.value)}
                                >
                                    <RuxOption label="--- select template ---" value={null} />
                                    {commandTemplates.map((template, templateIndex) => (
                                        <RuxOption key={templateIndex} label={template.name} value={templateIndex} />
                                    ))}
                                </RuxSelect>
                            )}
                            {loadTemplateCommandsCaller && (
                                <RuxButton
                                    onClick={handleConfirmation}
                                    style={{ marginLeft: '10px', marginRight: '10px' }}
                                >
                                    Load Template
                                </RuxButton>
                            )}

                            {saveBookingCommandsCaller && (
                                <RuxButton style={{ marginRight: '10px' }} onClick={() => saveBookingCommandsCaller(saveBookingCommands())}>Save Commands</RuxButton>
                            )}

                            {runAllCommandsCaller && (
                                <div>
                                    <div>
                                        <RuxButton onClick={isExecuting ? handleStopExecution : handleRunAllCommands}>
                                            {isExecuting ? "Stop" : "Run All"}
                                        </RuxButton>
                                    </div>
                                </div>
                            )}

                            {runAllCommandsAtTimesCaller && (
                                <RuxButton onClick={isExecuting ? handleStopExecution : handleRunAllAtTimes}>
                                    {isExecuting ? "Stop" : "Run All at Times"}
                                </RuxButton>
                            )}
                        </div>
                    )}
                    {hasPermission('template_manage_all') && (
                        <div style={{ marginLeft: '10px' }}>
                            {isCreatingTemplate || selectedTemplate ? (
                                <RuxButton id="open" secondary="" onClick={() => saveTemplateCaller(handleSaveTemplate())}>
                                    Save Template
                                </RuxButton>
                            ) : null}
                        </div>
                    )}
                </div>
            </div>

            <RuxContainer className="pass-plan">

                <RuxButton size="small" secondary icon="unfold-more" borderless onClick={handleExpandAll}>Expand All</RuxButton>
                <RuxButton size="small" secondary icon="unfold-less" borderless onClick={handleCollapseAll}>Collapse All</RuxButton>

                <div className="pass-plan_header">
                    {isCreatingTemplate || selectedTemplate ? (
                        <div>
                            <div>Template Name</div>
                            <RuxInput
                                style={{ marginBottom: '8px' }}
                                value={templateNameInput}
                                onRuxchange={(e) => setTemplateNameInput(e.target.value)}
                            />
                        </div>
                    ) : null}
                </div>

                <div className="pass-plan_header-wrapper">
                    {displayRelative && (<div className="pass-plan_header-step">Step</div>)}
                    <div style={{ marginLeft: saveTemplateCaller ? '200px' : '50px' }} className={`pass-plan_header-instruction`}>Instruction</div>
                    {hasPermission('booking_manage_*') && (
                        <div className="pass-plan_header-execute" style={{ opacity: saveTemplateCaller ? 0 : 1 }}>Execute</div>
                    )}
                </div>

                {commandList.map((item, index) => {
                    const template = commandTemplates.find(
                        (template) => template.steps.some((step) => step.command_id === item.command_id)
                    );

                    return (
                        <RuxTree className="pass-plan_tree-wrapper" key={index}>
                            <RuxTreeNode
                                selected
                                expanded={item.expanded}
                                onRuxtreenodeexpanded={() => handleNodeToggle(index)}
                                onRuxtreenodecollapsed={() => handleNodeToggle(index)}
                            >

                                <div style={{ marginLeft: "2px" }} className="pass-plan_executable-wrapper">

                                    <div className="step-id template-command">
                                        <div>
                                            <>
                                                <div style={{ display: "flex", alignItems: "center" }}>

                                                    {runAllCommandsCaller && (
                                                        <div key={item.uniqueId}>
                                                            <RuxCheckbox
                                                                checked={item.isChecked}
                                                                onRuxchange={() => handleCheckboxChange(index)}
                                                            />
                                                        </div>
                                                    )}

                                                    {displayStatus && (
                                                        <rux-status style={{ marginLeft: '8px' }} slot="prefix" status={item.status}></rux-status>
                                                    )}

                                                    {displayRelative && (
                                                        <div style={{ display: 'flex' }}>
                                                            <RuxSelect
                                                                value={item.relative_to}
                                                                onRuxchange={(e) => handleRelativeToChange(e, index)}
                                                                style={{ marginLeft: '8px' }}
                                                            >
                                                                <RuxOption label="--select--" value=""></RuxOption>
                                                                <RuxOption label="AOS" value="AOS">AOS</RuxOption>
                                                                <RuxOption label="LOS" value="LOS">LOS</RuxOption>
                                                            </RuxSelect>

                                                            <RuxInput
                                                                style={{ width: "60px", marginLeft: "8px" }}
                                                                value={item.relative_time !== '' ? item.relative_time : ''}
                                                                onRuxinput={(e) => handleRelativeTimeChange(e, index)}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {displayRelative && (
                                                    <div
                                                        style={{ fontSize: "0.8em", color: "gray", marginLeft: '40px', marginTop: '2px' }}
                                                        className="absolute-time"
                                                    >
                                                        {template ? calculateRelativeTime(item, booking) : ""}
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>

                                    <div
                                        className="pass-plan_command-name"
                                    >
                                        {item.name}
                                    </div>

                                    {hasPermission('booking_manage_*') && (
                                        <div className="two-icons">
                                            {executeButtonClickCaller && (
                                                <RuxButton
                                                    className="play-button"
                                                    iconOnly
                                                    icon={inProgressCommands.includes(`${item.command_id}_${index}`) ? "pause" : "play-arrow"}
                                                    onClick={() => executeButtonClickCaller(handleExecuteButtonClick(item, index))}
                                                />
                                            )}
                                            <RuxButton
                                                size="small"
                                                secondary
                                                icon-only
                                                icon="remove-circle-outline"
                                                borderless
                                                onClick={() => removeRow(index)}
                                            />
                                        </div>
                                    )}
                                </div>

                                {item.parameters.map((param, paramIndex) => (
                                    <RuxTreeNode slot={"node"} key={paramIndex} className="param-wrapper">
                                        <div slot="prefix" className="pass-plan_number-wrapper">
                                            {param.name}
                                        </div>
                                        <div className="pass-plan_mnemonic-wrapper">
                                            {param.type === 'BOOLEAN' && hasPermission('booking_manage_*') ? (
                                                <RuxSelect onRuxchange={(e) => handleOptionChange(e, index)} value={item.parameters.find(param => param.type === 'BOOLEAN')?.value || ''}>
                                                    <RuxOption label="--select--" value=""></RuxOption>
                                                    <RuxOption label="Yes" value="yes">Yes</RuxOption>
                                                    <RuxOption label="No" value="no">No</RuxOption>
                                                </RuxSelect>
                                            ) : param.type === 'NUMBER' || param.type === 'STRING' ? (
                                                <RuxInput
                                                    className="param-input"
                                                    name={`${param.name}_${item.commandId}_${item.uniqueId}`}
                                                    type={param.type === 'BOOLEAN' ? 'TEXT' : param.type}
                                                    value={param.value.toString()}
                                                    defaultValue={param.default || ''}
                                                    onRuxinput={(e) => handleInputChange(e, index, param.name, item.commandId, item.uniqueId)}
                                                />
                                            ) : null}
                                        </div>
                                    </RuxTreeNode>
                                ))}
                            </RuxTreeNode>
                        </RuxTree>
                    );
                })}

                {hasPermission('booking_manage_*') && (
                    <div slot="footer">
                        <SearchCommands
                            commands={stationCommandList}
                            setCommand={setCommand}
                            addToPassQueue={addToPassQueue}
                        />
                    </div>
                )}
            </RuxContainer>
        </rux-card>
    );
};

export default UniversalComponent;