import api from './config/axiosConfig';

const OBJECT_API_BASE_URL = 'objects';

export const createObject = (data) => {
    return api.post(OBJECT_API_BASE_URL, data);
};

export const getObjects = () => {
    return api.get(OBJECT_API_BASE_URL);
};

export const getObject = (objectId) => {
    const url = `${OBJECT_API_BASE_URL}/${objectId}`;
    return api.get(url);
};

export const updateObject = (objectId, data) => {
    const url = `${OBJECT_API_BASE_URL}/${objectId}`;
    return api.put(url, data);
};

export const deleteObject = (objectId) => {
    const url = `${OBJECT_API_BASE_URL}/${objectId}`;
    return api.delete(url);
};