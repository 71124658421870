import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import StationDetail from '../systems/stationManagement/StationDetail';
import {getStations} from '../apis/stationApi';
import StationCommands from '../systems/stationManagement/StationCommands';

function StationDetailScreen() {
    const [station, setStation] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchStation = async () => {
            try {
                console.log("ID from URL:", id); // Log the ID from URL
                const allStations = await getStations();
                const selectedStation = allStations.find(station => station.station_id === id);
                console.log("Selected Station:", selectedStation); // Log the selected station
                setStation(selectedStation);
            } catch (error) {
                console.error('Error fetching station:', error);
            }
        };
    
        fetchStation();
    }, [id]);

    console.log(station)

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: '33% 66%', justifyContent: 'space-evenly', padding: '10px 0' }}>
                <div style={{ gridColumn: 1 }}>
                    <rux-card>
                        <div slot="header">Station Detail</div>
                        <StationDetail station={station} />
                    </rux-card>
                </div>
                <div style={{ gridColumn: 2 }}>
                        <StationCommands station={station} />
                </div>
            </div>
        </div>
    );
}

export default StationDetailScreen;