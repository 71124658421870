import * as satCalc from 'satellite.js';

const SatelliteObject = ({ norad_id, tle, name }) => {
  const satrec = satCalc.twoline2satrec(tle[0], tle[1]);

  const getName = () => {
    return name || "[" + norad_id + "]";
  };

  const getPositionAtTime = (time) => {
    const gmst = satCalc.gstime(time);
    const positionAndVelocity = satCalc.propagate(satrec, time);
    const positionGd = satCalc.eciToGeodetic(positionAndVelocity.position, gmst);
    return {
      latitude: positionGd.latitude * (180 / Math.PI),
      longitude: positionGd.longitude * (180 / Math.PI),
    };
  };

  return {
    getName,
    getPositionAtTime
  };
};

export default SatelliteObject;