import api from './config/axiosConfig';

const RECEPTION_API_BASE_URL = 'reception';

export const getOpenReceptions = () => {
    return api.get(RECEPTION_API_BASE_URL);
};

export const activateReception = (data) => {
    return api.post(RECEPTION_API_BASE_URL, {
        ...data,
        comm_profile_id: data.comm_profile_id === '' ? null : data.comm_profile_id
    });
};

export const stopReception = (receptionId) => {
    const url = `${RECEPTION_API_BASE_URL}/${receptionId}`;
    return api.delete(url);
};
