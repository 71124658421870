import api from './config/axiosConfig';

const COMMAND_API_BASE_URL = '/commands';
const EXECUTE_BOOKING_COMAND_API_BASE_URL = '/scheduling/bookings';
const TEMPLATE_API_BASE_URL = '/templates';
const SAVED_BOOKING_COMANDS_API_BASE_URL = '/scheduling/bookings';
const STATION_COMMAND_API_BASE_URL = '/stations';

// Execute booking command
export const executeCommand = (booking_id, data) => {
    const url = `${EXECUTE_BOOKING_COMAND_API_BASE_URL}/${booking_id}/command`;
    return api.post(url, data);
};

// List of station commands
export const getStationCommands = () => {
    return api.get(COMMAND_API_BASE_URL)
};

//Command Templates
export const getCommandTemplates = () => {
    return api.get(TEMPLATE_API_BASE_URL)
};

export const createCommandTemplate = (data) => {
    return api.post(TEMPLATE_API_BASE_URL, data);
};

export const editCommandTemplate = (templateId, data) => {
    const url = `${TEMPLATE_API_BASE_URL}/${templateId}`;
    return api.put(url, data);
};

export const deleteCommandTemplate = (templateId) => {
    const url = `${TEMPLATE_API_BASE_URL}/${templateId}`;
    return api.delete(url);
};

//Commands saved for booking
export const getSavedBookingCommands = (bookingId) => {
    const url = `${SAVED_BOOKING_COMANDS_API_BASE_URL}/${bookingId}/sequence`;
    return api.get(url);
};

export const saveCommandsForBooking = ({ bookingId, steps }) => {
    const url = `${SAVED_BOOKING_COMANDS_API_BASE_URL}/${bookingId}/sequence`;
    console.log('Data sent to saveCommandsForBooking:', { bookingId, steps });
 return api.post(url,  steps );
};

//Send Commands to Station
export const executeCommandToStation = (station_id, data) => {
    const url = `${STATION_COMMAND_API_BASE_URL}/${station_id}/command`;
    return api.post(url, data);
};

