import React, { useEffect, useState } from "react";
import * as api from '../../../apis/commandApi';
import UniversalComponent from './UniversalComponent';

const TemplateManagement = ({ selectedTemplate, isCreatingTemplate, setSelectedTemplate, setIsCreatingTemplate, handleEditTemplate }) => {
    const [templateManagementCommandList, setTemplateManagementCommandList] = useState([]);
    const [stationCommandList, setStationCommandList] = useState([]);
    const [templateNameInput, setTemplateNameInput] = useState('');

    useEffect(() => {
        if (selectedTemplate) {
            setTemplateNameInput(selectedTemplate.name || '');
        } else {
            setTemplateManagementCommandList([]);
            setTemplateNameInput('');
        }
    }, [selectedTemplate]);

    useEffect(() => {
        handleTemplateChange(selectedTemplate);
    }, [selectedTemplate]);

    useEffect(() => {
        const loadStationCommands = async () => {
            try {
                const stationCommand = await api.getStationCommands() || [];
                console.log('Station Commands:', stationCommand);
                setStationCommandList(stationCommand);
            } catch (error) {
                console.error('Error loading station commands:', error);
            }
        }

        loadStationCommands();
    }, []);

    const handleTemplateChange = (template) => {
        if (template && template.template_id) {
            setTemplateManagementCommandList([]);

            if (template && template.steps) {
                const newCommandList = [];

                template.steps.forEach((templateStep) => {
                    const matchingCommand = stationCommandList.find(
                        (command) => command.command_id === templateStep.command_id
                    );

                    if (matchingCommand) {
                        const parametersArray = Array.isArray(templateStep.parameters)
                            ? templateStep.parameters
                            : [templateStep.parameters];

                        const commandItemWithOption = {
                            ...matchingCommand,
                            origin: 'template',
                            uniqueId: Date.now() + Math.random(),
                            parameters: matchingCommand.parameters.map((param) => {
                                const paramName = param.name;
                                const paramValue = templateStep.parameters && paramName in templateStep.parameters
                                    ? String(templateStep.parameters[paramName])
                                    : param.value || '';

                                return { ...param, value: paramValue };
                            }),
                            expanded: true,
                            relative_time: templateStep.relative_time || 0,
                            relative_to: templateStep.relative_to || '',
                        };

                        newCommandList.push(commandItemWithOption);
                    }
                });

                setTemplateManagementCommandList(newCommandList);
            }
        }
    };

    const saveTemplateCaller = async () => {
        try {
            console.log('saveTemplateCaller function invoked!');

        } catch (error) {
            console.error('Error in saveTemplateCaller:', error);
        }
    };

    const displayRelative = async () => {
        try {
            console.log('displayRelative function invoked!');

        } catch (error) {
            console.error('Error in displayRelative:', error);
        }
    };

    return (
        <div>
            <UniversalComponent
                templateManagementCommandList={templateManagementCommandList}
                handleEditTemplate={handleEditTemplate}
                templateNameInput={templateNameInput}
                setTemplateManagementCommandList={setTemplateManagementCommandList}
                isCreatingTemplate={isCreatingTemplate}
                selectedTemplate={selectedTemplate}
                setTemplateNameInput={setTemplateNameInput}
                setSelectedTemplate={setSelectedTemplate}
                setIsCreatingTemplate={setIsCreatingTemplate}
                saveTemplateCaller={saveTemplateCaller}
                displayRelative={displayRelative}
                label="Template Management"
            />
        </div>
    );
};

export default TemplateManagement;