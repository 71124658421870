import React, { useState, useEffect } from 'react';

import ObjectTable from "../systems/objectManagement/components/ObjectTable";
import ObjectForm from "../systems/objectManagement/components/ObjectForm";
import { RuxAccordionItem } from "@astrouxds/react";
import {useAuth} from "../auth/AuthContext";

const ObjectManagementScreen = () => {
    const { hasPermission } = useAuth()
    const [selectedObject, setSelectedObject] = useState(null);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    useEffect(() => {
        document.title = "GCN Objects";
    }, []);

    const handleEditObject = (selectedObject) => {
        if (!hasPermission('object_manage_all')) {
            return;
        }
        setSelectedObject(selectedObject);
        setIsAccordionOpen(true);
    };

    const handleAccordionCollapse = () => {
        setIsAccordionOpen(false); // Collapse the accordion
    };

    const handleClearSelectedObject = () => {
        setSelectedObject(null);
        setIsAccordionOpen(false);
    };

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '100%',
            justifyContent: 'space-evenly',
            margin: '12px',
            gap: '12px'
        }}>
            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    {hasPermission('object_manage_all') && (
                        <RuxAccordionItem
                            expanded={isAccordionOpen ? true : undefined}
                            onRuxcollapsed={handleAccordionCollapse}
                        >
                            <div slot="label">Add Object</div>
                            <ObjectForm selectedObject={selectedObject} clearSelectedObject={handleClearSelectedObject} />
                        
                        </RuxAccordionItem>
                    )}

                    {hasPermission('object_view_all') && <>
                        <br />
                        <div slot="header">Objects</div>
                        <ObjectTable handleEditObject={handleEditObject} />
                    </>}

                </rux-container>
            </div>
        </div>
    );
}

export default ObjectManagementScreen;

