import React, { useState, useEffect } from 'react';
import {
  RuxButton,
  RuxInput,
  RuxMenu,
  RuxMenuItem,
  RuxPopUp,
} from "@astrouxds/react";
import "./SearchCommands.css";

const SearchCommands = ({ commands, setCommand, addToPassQueue }) => {
  const [inputValue, setInputValue] = useState("");
  const [currentCommand, setCurrentCommand] = useState(null);
  const [recentCommands, setRecentCommands] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const generateCommandString = (command) => {
    const parametersWithDescription = command.parameters.filter(param => param.description.trim() !== "");
    if (parametersWithDescription.length > 0) {
      return `${command.name}, (${parametersWithDescription.map(param => param.description).join(", ")})`;
    } else {
      return command.name;
    }
  };

  const filteredCommands = commands.filter((command) =>
    generateCommandString(command).toLowerCase().includes(inputValue.toLowerCase())
  );

  useEffect(() => {
    if (filteredCommands.length === 1 && !currentCommand) {
      setCurrentCommand(filteredCommands[0]);
    }
  }, [filteredCommands, currentCommand]);

  const filteredRecentCommands = recentCommands.filter((command) =>
    generateCommandString(command).toLowerCase().includes(inputValue.toLowerCase())
  );

  const sendCommand = (current) => {
    setCommand(current);
    addToPassQueue(current);
    setCurrentCommand(null);
    setInputValue("");

    setRecentCommands((prevCommands) => {
      const filteredPrevCommands = prevCommands.filter(
        (command) => command !== current
      );
      if (filteredPrevCommands.length >= 5) {
        filteredPrevCommands.pop();
      }
      return [current, ...filteredPrevCommands];
    });
  };

  const openPopup = () => {
    const searchPopup = document.getElementById("commands_input-pop-up");
    if (searchPopup) {
      searchPopup.show();
      const menu = document.getElementById("commands_input-menu");
      if (menu) {
        menu.focus();
      }
    }
  };

  const closePopup = () => {
    setCurrentCommand(null); // Reset currentCommand when popup is closed
    const searchPopup = document.getElementById("commands_input-pop-up");
    if (searchPopup) {
      searchPopup.hide();
    }
  };

  const handleKeyPress = (e) => {
    if (e.code === "Enter" && currentCommand) {
      sendCommand(currentCommand);
      closePopup();
    } else if (e.code === "Enter" && !currentCommand) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (filteredCommands.length >= 1 && !currentCommand) {
      setCurrentCommand(filteredCommands[0]);
    }
  }, [filteredCommands, currentCommand]);

  useEffect(() => {
    if (currentCommand) {
      const selectedItem = document.querySelector(`[value="${currentCommand.command_id}"]`);
      if (selectedItem) {
        selectedItem.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
      }
    }
  }, [currentCommand]);

  const handleArrowKeyPress = (e) => {
    if (currentCommand === null) return;

    const currentIndex = filteredCommands.findIndex(
      (command) => command.command_id === currentCommand?.command_id
    );

    let nextIndex;

    if (e.key === "ArrowUp") {
      nextIndex = currentIndex > 0 ? currentIndex - 1 : filteredCommands.length - 1;
    } else if (e.key === "ArrowDown") {
      nextIndex = currentIndex < filteredCommands.length - 1 ? currentIndex + 1 : 0;
    }
    setCurrentCommand(filteredCommands[nextIndex]);
  };

  return (
    <>
      <RuxPopUp
        id="commands_input-pop-up"
        className="commands_input-pop-up"
        placement="top-start"
        closeOnSelect={true}
        onRuxpopupclosed={closePopup}
      >
        <RuxButton
          slot="trigger"
          iconOnly
          icon="unfold-more"
        />
        {filteredCommands.length >= 1 ? (
          <RuxMenu
            id="commands_input-menu"
            className="commands_input-menu"
            onRuxmenuselected={(e) => {
              const commandId = e.detail.value;
              const command = commands.find(
                (command) => command.command_id === commandId
              );
              setCurrentCommand(command || null);
              setInputValue(
                generateCommandString(command)
              );
            }}
          >
            {filteredRecentCommands.map((item, index) => (
              <RuxMenuItem
              selected={isInputFocused && !!currentCommand && currentCommand.command_id === item.command_id}
              key={index}
              value={item.command_id?.toString()}
            >
              {item.name}
              {item.parameters.some(param => param.description.trim() !== "") && ', '}
              <i>
                {item.parameters.filter(param => param.description.trim() !== "").map(param => param.description).join(", ")}
              </i>
            </RuxMenuItem>
            ))}

            <h4 className="menu-title">Recent Commands</h4>
            {filteredCommands.map((item, index) => (
              <RuxMenuItem
                selected={isInputFocused && !!currentCommand && currentCommand.command_id === item.command_id}
                key={index}
                value={item.command_id?.toString()}
              >
                {generateCommandString(item)}
              </RuxMenuItem>
            ))}
          </RuxMenu>
        ) : (
          <span className="commands_no-match">
            No commands match the given search parameters.
          </span>
        )}
      </RuxPopUp>
      <RuxInput
        type="search"
        placeholder="Start typing to search commands..."
        value={inputValue}
        onRuxinput={(e) => {
          setCurrentCommand(null);
          setInputValue(e.target.value);
          openPopup();
        }}
        onClick={() => {
          openPopup();
        }}
        onRuxfocus={() => {
          openPopup();
          setIsInputFocused(true);
        }}
        onBlur={() => {
          setIsInputFocused(false);
        }}
        onKeyDown={(e) => {
          handleKeyPress(e);
          handleArrowKeyPress(e);
        }}
      />
      <RuxButton
        onClick={() => {
          if (currentCommand) {
            sendCommand(currentCommand);
          }
        }}
      >
        Add to Queue
      </RuxButton>

    </>
  );
};

export default SearchCommands;