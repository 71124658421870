import React, { useState, useEffect } from 'react';
import {
  RuxButton,
  RuxTable,
  RuxTableBody,
  RuxTableHeaderRow,
  RuxTableHeaderCell,
  RuxTableRow,
  RuxTableCell,
  RuxDialog,
  RuxTableHeader,
  RuxCard,
  RuxInput
} from '@astrouxds/react';
import * as accountApi from '../../apis/accountApi';
import * as userApi from '../../apis/userApi';
import { useAuth } from '../../auth/AuthContext';
import { notify } from "../../libs/notificationSystem/notificationManager";

function AccountTable({ onSelectAccount }) {
  const [accounts, setAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const { hasPermission, user } = useAuth();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [form, setForm] = useState({
    account_name: '',
  });
  const [isEditAccountDialogOpen, setIsEditAccountDialogOpen] = useState(false);
  const [editForm, setEditForm] = useState({
    account_name: '',
  });

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const loadedAccounts = await accountApi.getAccounts() || [];
        loadedAccounts.sort((a, b) => a.account_name.localeCompare(b.account_name));
        setAccounts(loadedAccounts);

        if (user) {
          const userAccount = loadedAccounts.find((account) => account.account_id === user.account_id);
          setSelectedAccount(userAccount);
        }
      } catch (error) {
        console.error('Error loading accounts:', error);
      }
    };

    loadAccounts();
  }, [user]);

  const openDialog = () => {
    if (!hasPermission('user_manage_*')) return;
    setIsAccountDialogOpen(true);
    setIsEditingAccount(false);
    setForm({
      account_name: '',
    });
  };

  const closeDialog = () => {
    setIsAccountDialogOpen(false);
    setForm({
      account_name: '',
    });
    setIsEditingAccount(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleConfirmClick = async () => {
    if (!hasPermission('account_manage_all')) return;
    try {
      const { account_name } = form;
      const newAccount = {
        account_name: account_name || null,
      };
      let response;
      response = await accountApi.createAccount(newAccount);
      console.log(response);
      notify('Account created', 'normal');
  
      setIsAccountDialogOpen(false);
      setForm({
        account_name: '',
      });
  
      const updatedAccounts = await accountApi.getAccounts();
      setAccounts(updatedAccounts);
  
      const createdAccount = response;
      setSelectedAccount(createdAccount);
  
      onSelectAccount(createdAccount, []);
    } catch (error) {
      console.error('Error creating account:', error);
      notify('Error creating account.', 'critical');
    }
  };

  const handleAccountClick = async (account) => {
    try {
      const usersForAccount = await userApi.getUsersByAccountId(account.account_id) || [];
      setUsers(usersForAccount);

      setSelectedAccount(account);
      setForm({
        account_name: account.account_name,
      });
      setIsEditingAccount(false);
      setIsAccountDialogOpen(false);

      onSelectAccount(account, usersForAccount);
    } catch (error) {
      console.error('Error loading users:', error);
    }
  };

   const handleEditButtonClick = (account) => {
    if (!hasPermission('account_manage_all')) return;
    setSelectedAccount(account);
    setEditForm({
      account_name: account.account_name,
    });
    setIsEditingAccount(true);
    setIsEditAccountDialogOpen(true);
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleEditAccountClick = async () => {
    if (!hasPermission('account_manage_all')) return;
    try {
      const { account_name } = editForm;
      const updatedAccount = {
        account_name: account_name || null,
      };

      const response = await accountApi.updateAccount(selectedAccount.account_id, updatedAccount);
      console.log(response);
      notify('Account updated', 'normal');

      setIsEditAccountDialogOpen(false);
      setEditForm({
        account_name: '',
      });

      const updatedAccounts = await accountApi.getAccounts();
      setAccounts(updatedAccounts);

      const editedAccount = updatedAccounts.find((account) => account.account_id === selectedAccount.account_id);
      setSelectedAccount(editedAccount);

      onSelectAccount(editedAccount, []);
    } catch (error) {
      console.error('Error editing account:', error);
      notify('Error updating account.', 'critical');
    }
  };

  const handleRemoveAccountClick = async (account) => {
    if (!hasPermission('account_manage_all')) return;
    setSelectedAccount(account);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogConfirm = async () => {
    setIsConfirmationDialogOpen(false);
    try {
      const usersToDelete = users.filter((user) => user.account_id === selectedAccount.account_id);
      await Promise.all(usersToDelete.map((user) => userApi.deleteUser(user.user_id)));

      const response = await accountApi.deleteAccount(selectedAccount.account_id);
      console.log(response);
      notify(`Account with ID ${selectedAccount.account_name} deleted successfully`, 'normal');

      const updatedAccounts = await accountApi.getAccounts();
      setAccounts(updatedAccounts);

      const userAccount = updatedAccounts.find((account) => account.account_id === user.account_id);
      setSelectedAccount(userAccount);

      onSelectAccount(userAccount, []);
    } catch (error) {
      console.error('Error removing account:', error);
      notify(`Error deleting account with ID ${selectedAccount.account_id}: ${error.message}`, 'critical');
    }
  };

  return (
    <RuxCard style={{ width: '100%' }}>
      <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Accounts</span>
        {hasPermission('account_manage_all') && (
          <RuxButton secondary="" id="open" onClick={openDialog}>
            Add Account
          </RuxButton>
        )}
      </div>

      <RuxTable>
        <RuxTableHeader>
          <RuxTableHeaderRow>
            <RuxTableHeaderCell>Select Account</RuxTableHeaderCell>
            <RuxTableHeaderCell></RuxTableHeaderCell>
          </RuxTableHeaderRow>
        </RuxTableHeader>
        <RuxTableBody>
          {accounts.length <= 0 ? (
            <RuxTableRow>
              <RuxTableCell>
                No accounts
              </RuxTableCell>
            </RuxTableRow>
          ) : (
            accounts.map((account, index) => (
              <RuxTableRow selected={account.account_id === selectedAccount?.account_id ? '' : 'false'} onClick={() => handleAccountClick(account)} key={index} style={{ cursor: 'pointer' }}>
                <RuxTableCell>{account.account_name}</RuxTableCell>
                <RuxTableCell style={{ textAlign: 'right' }}>
                  {hasPermission('account_manage_all') && (
                    <>
                      <RuxButton
                        size="small"
                        secondary
                        icon-only
                        icon="create"
                        borderless
                        onClick={() => handleEditButtonClick(account)}
                      />
                      <RuxButton
                        size="small"
                        secondary
                        icon-only
                        icon="remove-circle-outline"
                        borderless
                        onClick={() => handleRemoveAccountClick(account)}
                      />
                    </>
                  )}
                </RuxTableCell>
              </RuxTableRow>
            ))
          )}
        </RuxTableBody>
      </RuxTable>

      <div>
        <RuxDialog id="dialog-with-slot" open={isAccountDialogOpen} onRuxclosed={closeDialog}>
          <span slot="header">{isEditingAccount ? 'Edit Account' : 'Add Account'}</span>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: '1rem' }}>Account</span>
            <RuxInput
              label="Account Name"
              placeholder="Account Name"
              name="account_name"
              onRuxinput={handleFormChange}
              value={form.account_name}
            />
          </div>
          <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <RuxButton id="cancel" secondary="" onClick={closeDialog}>
                Cancel
              </RuxButton>
              <RuxButton id="confirm" onClick={handleConfirmClick}>
                Confirm
              </RuxButton>
          </div>
        </RuxDialog>

        <RuxDialog id="edit-account-dialog" open={isEditAccountDialogOpen} onRuxclosed={() => setIsEditAccountDialogOpen(false)}>
        <span slot="header">Edit Account</span>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginBottom: '1rem' }}>Account</span>
          <RuxInput
            label="Account Name"
            placeholder="Account Name"
            name="account_name"
            onRuxinput={handleEditFormChange}
            value={editForm.account_name}
          />
        </div>
        <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <RuxButton id="cancel" secondary="" onClick={() => setIsEditAccountDialogOpen(false)}>
            Cancel
          </RuxButton>
          <RuxButton id="confirm" onClick={handleEditAccountClick}>
            Confirm
          </RuxButton>
        </div>
      </RuxDialog>

        <RuxDialog id="confirmation-dialog" open={isConfirmationDialogOpen} onRuxclosed={() => setIsConfirmationDialogOpen(false)}>
          <span slot="header">Confirmation</span>
          <div>
            Are you sure you want to delete the <strong>{selectedAccount?.account_name}</strong> account? All users associated with this account will be deleted as well.
          </div>
          <div slot="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <RuxButton id="cancel" secondary="" onClick={() => setIsConfirmationDialogOpen(false)}>
              Cancel
            </RuxButton>
            <RuxButton id="confirm" onClick={handleConfirmationDialogConfirm}>
              Confirm
            </RuxButton>
          </div>
        </RuxDialog>
      </div>
    </RuxCard>
  );
}

export default AccountTable;