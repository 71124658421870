import React, {useState, useEffect} from 'react';
import {
    RuxButton,
    RuxTable,
    RuxTableBody,
    RuxTableHeaderRow,
    RuxTableHeaderCell,
    RuxTableRow,
    RuxTableCell, RuxTableHeader
} from '@astrouxds/react';
import { Link } from "react-router-dom";
import * as api from "../../../apis/objectApi";
import {useAuth} from "../../../auth/AuthContext";
import {notify} from "../../../libs/notificationSystem/notificationManager";

const ObjectTable = (props) => {
    const [objects, setObjects] = useState([]);
    const {hasPermission} = useAuth()

    useEffect(() => {
        const loadObjects = async () => {
            try {
                const objects = await api.getObjects() || [];
                objects.sort((a, b) => a.name.localeCompare(b.name));
                setObjects(objects);
            } catch (error) {
                console.error('Error loading objects:', error);
            }
        };

        loadObjects();
        const interval = setInterval(loadObjects, 1000); // Update objects every second

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    const handleEditObjectClick = (object_id) => {
        if (!hasPermission('object_manage_all')) return;

        const selectedObject = objects.find(obj => obj.object_id === object_id);
        if (props.handleEditObject) {
            props.handleEditObject(selectedObject);
        }
    };

    const handleDeleteObjectClick = async (object_id) => {
        if (!hasPermission('object_manage_all')) return;

        try {
            await api.deleteObject(object_id);
            console.log(`Object with ID ${object_id} deleted successfully`);
            notify(`Object deleted successfully`, 'normal');
        } catch (error) {
            console.error(`Error deleting object with ID ${object_id}: ${error.message}`);
            notify(`Error deleting object with ID ${object_id}: ${error.message}`, 'critical');
        }
    };

    return (
        <div>

            <RuxTable>
                <RuxTableBody>
                    <RuxTableHeaderRow selected={false}>
                        <RuxTableHeaderCell>NORAD ID</RuxTableHeaderCell>
                        <RuxTableHeaderCell>Name</RuxTableHeaderCell>
                        <RuxTableHeaderCell>Note</RuxTableHeaderCell>
                        <RuxTableHeaderCell>Template</RuxTableHeaderCell>
                        <RuxTableHeaderCell>TLE</RuxTableHeaderCell>
                        {hasPermission('object_manage_all') && (
                            <RuxTableHeaderCell></RuxTableHeaderCell>
                        )}
                    </RuxTableHeaderRow>
                    {objects
                        .map((object, i) => (
                            <RuxTableRow key={i}>
                                <RuxTableCell>{parseInt(object.norad_id)}</RuxTableCell>
                                <RuxTableCell>{object.name}</RuxTableCell>
                                <RuxTableCell>{object.note || "N/A"}</RuxTableCell>
                                <RuxTableCell>{object.template_id ? (<>
                                    {object.template_id}
                                    {hasPermission("template_manage_all") && <Link to={`/templates/${object.template_id}`}><rux-button secondary icon-only icon="launch" borderless /></Link>}
                                </>) : "N/A"}</RuxTableCell>
                                <RuxTableCell>
                                    {object.tle ? (
                                        <>
                                            <div>{object.tle.line_1}</div>
                                            <div>{object.tle.line_2}</div>
                                        </>
                                    ) : (
                                        <div>N/A</div>
                                    )}
                                </RuxTableCell>
                                {hasPermission('object_manage_all') && (
                                    <RuxTableCell style={{textAlign: 'right'}}>
                                        <RuxButton size="small" secondary icon-only icon="create" borderless
                                                   onClick={() => handleEditObjectClick(object.object_id)}/>
                                        <RuxButton size="small" secondary icon-only icon="remove-circle-outline"
                                                   borderless
                                                   onClick={() => handleDeleteObjectClick(object.object_id)}/>
                                    </RuxTableCell>
                                )}
                            </RuxTableRow>
                        ))}
                </RuxTableBody>
            </RuxTable>
        </div>
    );
};

export default ObjectTable;
