
let addNotification = null;
let removeNotification = null;

export const setNotificationHandler = (add, remove) => {
    addNotification = add;
    removeNotification = remove;
};

export const notify = (message, status = 'standby', closeAfter = 4000, id = null) => {
    if (addNotification) {
        addNotification(message, status, closeAfter, id);
    }
};

export const clearNotification = (id) => {
    if (removeNotification) {
        removeNotification(id);
    }
};
