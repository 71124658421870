import React, { useEffect, useState } from 'react';
import WorldMapMonitoring from '../systems/map/WorldMapMonitoring';

const MapScreen = () => {

  useEffect(() => {
    document.title = "GCN Map";
  }, []);

  return (
    <div>
      <WorldMapMonitoring/>
    </div>
  );
};

export default MapScreen;