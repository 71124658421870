import React, {useState} from 'react';
import {RuxButton, RuxContainer, RuxInput} from "@astrouxds/react";
import {useAuth} from "../auth/AuthContext";
import * as api from "../apis/authApi";
import {notify} from "../libs/notificationSystem/notificationManager";

function LoginScreen() {
    const {login} = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.login({username, password});

            if (response.token) {
                // Authentication successful
                // Store the user's authentication token in state or local storage
                login(response, response.token);
            }
        } catch (error) {
            console.log(error);
            notify('Invalid username or password', 'critical');
        }
    };

    return (
        <div>
            <div className="login-container">
                <RuxContainer>
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit}>
                        <RuxInput
                            label="Username"
                            type="text"
                            value={username}
                            onInput={(e) => setUsername(e.target.value)}
                        ></RuxInput>


                        <RuxInput
                            label="Password"
                            type="password"
                            value={password}
                            onInput={(e) => setPassword(e.target.value)}
                        ></RuxInput>

                        <RuxButton type="submit">Login</RuxButton>
                    </form>
                </RuxContainer>

            </div>
        </div>
    );
}

export default LoginScreen;
